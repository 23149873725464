import axios from "axios";
import { API_CONTABLE, API_CUENTAS } from "config/api.config";
import UserService from "config/UserService";
import { AuthAxios } from "./config";

export const getAccountingPlan = async (extraPath: "inventory/" | "voucher/" | "", headquarterCode: string, companyUrl: string, year: number) => {
  const res = await axios.get(`${API_CUENTAS}/accounting-plan/${extraPath}${headquarterCode}/${year}/${companyUrl}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postAccount = async (year: number, headquarterCode: string, companyUrl: string, data: object) => {
  const res = await axios.post(`${API_CUENTAS}/accounting-plan/insert/${year}/${headquarterCode}/${companyUrl}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putAccount = async (accountId: string, data: object) => {
  const res = await axios.put(`${API_CUENTAS}/accounting-plan/${accountId}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const uploadAccounts = async (year: number, headquarterCode: string, companyUrl: string, data: object) => {
  const res = await axios.post(`${API_CUENTAS}/accounting-plan/upload/${year}/${headquarterCode}/${companyUrl}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const uploadAuxAccounts = async (year: number, headquarterCode: string, companyUrl: string, data: object) => {
  const res = await axios.post(`${API_CUENTAS}/aux-account/upload/${headquarterCode}/${year}/${companyUrl}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const uploadBudget = async (year: number, headquarterCode: string, companyUrl: string, data: object) => {
  const res = await axios.post(`${API_CUENTAS}/budget/upload/${headquarterCode}/${year}/${companyUrl}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const uploadVoucher = async (year: number, headquarterCode: string, companyUrl: string, data: object, headquarterId?: string) => {
  const res = await axios.post(`${API_CUENTAS}/import/massive-vouchers/${year}/${headquarterCode}/${headquarterId}/${companyUrl}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const uploadVoucherXML = async (year: number, headquarterCode: string, companyUrl: string, data: object, headquarterId?: string) => {
  const res = await axios.post(`${API_CUENTAS}/import/xml/${year}/${headquarterCode}/${headquarterId}/${companyUrl}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const uploadRRHHTxt = async (year: number, headquarterCode: string, companyUrl: string, data: object, headquarterId?: string) => {
  const res = await axios.post(`${API_CUENTAS}/import/txt/${year}/${headquarterCode}/${headquarterId}/${companyUrl}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getWorkingPeriods = async (headquarterCode: string, companyUrl: string, year: number) => {
  const res = await axios.get(`${API_CONTABLE}/working-period/${companyUrl}/${headquarterCode}/${year}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postWorkingPeriods = async (companyUrl: string, headquarterCode: string, year: number, data: object) => {
  const res = await axios.post(`${API_CONTABLE}/working-period/${companyUrl}/${headquarterCode}/${year}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// BUDGET

export const getBudget = async (headquarterCode: string, year: number, companyUrl: string) => {
  const res = await axios.get(`${API_CUENTAS}/budget/list/${headquarterCode}/${year}/${companyUrl}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postBudget = async (headquarterCode: string, year: number, companyUrl: string, data: object) => {
  const res = await axios.post(`${API_CUENTAS}/budget/list/${headquarterCode}/${year}/${companyUrl}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putBudget = async (budgetId: string, data: object) => {
  const res = await axios.put(`${API_CUENTAS}/budget/list/${budgetId}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// FILES

export const getAllFiles = async (period: number, year: number, company: string) => {
  const res = await axios.get(`${API_CONTABLE}/getallfiles/${year}/${period}/${company}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getCompanyFiles = async (period: number, year: number, companyUrl: string, headquarterCode: string) => {
  const res = await axios.get(`${API_CONTABLE}/companyFiles/${companyUrl}/${headquarterCode}/${year}/${period}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postFile = async (companyUrl: string, headquarterCode: string, data: object) => {
  const res = await axios.post(`${API_CONTABLE}/saveFiles/${companyUrl}/${headquarterCode}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putFile = async (fileId: string, data: object) => {
  const res = await axios.put(`${API_CONTABLE}/file/${fileId}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// ACCOUNT ASSISTANT

export const getAccountAssistant = async (companyUrl: string, headquarterCode: string, year: number) => {
  const res = await axios.get(`${API_CUENTAS}/aux-account/list/${headquarterCode}/${year}/${companyUrl}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postAccountAssistant = async (companyUrl: string, headquarterCode: string, year: number, data: object) => {
  let res = await axios.post(`${API_CUENTAS}/aux-account/${headquarterCode}/${year}/${companyUrl}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putAccountAssistant = async (accountAssistantId: string, data: object) => {
  let res = await axios.put(`${API_CUENTAS}/aux-account/${accountAssistantId}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getAccountAssistantByAccount = async (data: object) => {
  const res = await axios.post(`${API_CUENTAS}/aux-account/account`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getTypeCoins = async () => {
  const res = await axios.get(`${API_CUENTAS}/monedas`, { headers: { Authorization: UserService.token() } });
  return res.data;
};

export const getExchangeRateByDate = async (data: object) => {
  const res = await AuthAxios.post(`/exchange-rate`, data);
  return res.data;
};
