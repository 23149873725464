import { SVGProps } from "react";
const Religiosos = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={124} height={106} fill="none" {...props}>
    <path fill="#9FCBF3" d="M122.814 57.92c1.54.89 1.54 2.35 0 3.23l-32.63 18.84-50.09-29.11 32.63-18.84c1.53-.88 4.04-.88 5.58.02l44.5 25.86h.01Z" />
    <path fill="#0086CF" d="m122.826 61.15-32.63 18.84-.02 2.68 32.63-18.84c.76-.44 1.14-1.02 1.15-1.6l.02-2.68c0 .58-.39 1.16-1.15 1.6Z" />
    <path fill="#DCBE89" d="m123.095 59.65-34.06 19.66-1.97 1.14-47.76-27.75 1.97-1.14 34.06-19.66 47.76 27.75Z" />
    <path fill="#0274BD" d="m88.13 83.52 2.08-3.53-50.09-29.11-1.24.72-3.95 2.28 50.09 29.1 3.11.54Z" />
    <path fill="#0274BD" d="m87.238 84.71 2.08-3.53-47.89-27.82-1.24.71h.01l-3.94 2.28 50.09 29.11.89-.75Z" />
    <path fill="#BCD1E4" d="m87.105 72.94-.04 7.51-47.76-27.75.04-7.51 47.76 27.75Z" />
    <path
      fill="#BED2E3"
      d="m90.208 79.99-.9.52c-.2.11-.32.33-.32.56 0 .23-.12.44-.32.56l-2.11 1.22a.215.215 0 0 1-.32-.19c0-.16-.18-.27-.32-.19l-.89.51-.02 2.68 1.01-.58c.08-.05.18.01.18.11 0 .24.25.38.45.27l1.89-1.09c.27-.16.44-.44.44-.76 0-.15.08-.28.2-.35l1.01-.58.02-2.68v-.01ZM40.726 50.53l-.9.52c-.2.11-.32.33-.32.56 0 .23-.12.44-.32.56l-2.73 1.58a.215.215 0 0 1-.32-.19c0-.16-.18-.27-.32-.19l-.89.52-.02 2.68 1.01-.58c.08-.05.18.01.18.11 0 .24.25.38.45.27l2.51-1.45c.27-.16.44-.44.44-.76 0-.15.08-.28.2-.35l1.01-.58.02-2.68v-.02Z"
    />
    <path
      fill="#9FCBF3"
      d="m85.02 82.98-32.63 18.84c-1.53.88-4.04.88-5.58-.02L2.32 75.95c-1.54-.89-1.54-2.35-.01-3.23l32.63-18.84 50.09 29.11-.01-.01Z"
    />
    <path
      fill="#0086CF"
      d="M52.386 101.82c-1.53.88-4.04.88-5.58-.02L2.316 75.95c-.78-.45-1.16-1.04-1.16-1.63V77c0 .59.38 1.18 1.15 1.62l44.5 25.86c1.54.89 4.05.9 5.58.02l32.63-18.84.02-2.68-32.63 18.84h-.02Z"
    />
    <path
      fill="#F1F1F1"
      d="m85.83 81.17-1.97 1.14-34.06 19.66L2.04 74.22 36.1 54.55l1.97-1.13 47.76 27.75ZM123.151 52.14l-36.61 21.14-47.76-27.76 36.61-21.13 47.76 27.75Z"
    />
    <path fill="#BED2E3" d="m123.151 52.14-.04 7.51-36.03 20.8-.53-7.18c6.87-13.9 25.65-16.11 36.6-21.14v.01Z" />
    <path fill="#BCD1E4" d="m49.84 94.46-.04 7.51L2.04 74.22l.05-7.51 47.75 27.75Z" />
    <path fill="#F1F1F1" d="m86.54 73.28-36.7 21.18L2.09 66.71l36.69-21.19 47.76 27.76Z" />
    <path fill="#BED2E3" d="m86.54 73.28-.71 7.89-36.03 20.8.04-7.51c12.35-9.01 27.77-26.89 36.7-21.19v.01Z" />
    <path fill="#C8D0D7" d="M120.171 44.11s-24.42 10.15-33.63 29.17l-47.76-27.75 33.63-29.17 47.76 27.75Z" />
    <path fill="#F1F1F1" d="M57.855 0c-4.06 12.78-18.59 27.9-19.66 45.19l48.33 28.09c1.07-17.29 16.55-34.12 20.61-46.89L57.855 0Z" />
    <path
      fill="#2CB9E4"
      d="m75.89 103.699 1.17-3.06 4.82-.42s-.27-.15-.69-.39c-2.12-1.23-8.47-5.07-9.67-7.82-.4-.92-.58-1.77-.75-2.6-.48-2.29-.93-4.46-5.6-7.88-.66-.49-1.41-.96-2.22-1.43-1.94-1.12-4.28-2.22-6.86-3.44-2.97-1.4-6.27-2.96-9.53-4.84-3.87-2.24-7.68-4.93-10.76-8.34-3.63-4.02-6.72-6.76-9.26-8.22-2.32-1.34-4.17-1.61-5.55-.81L15 57.929c1.38-.8 3.23-.53 5.55.81 2.54 1.46 5.63 4.21 9.26 8.22 3.09 3.41 6.89 6.1 10.76 8.34 3.25 1.88 6.56 3.44 9.53 4.84 2.59 1.22 4.92 2.32 6.86 3.44.82.47 1.56.94 2.22 1.43 4.66 3.42 5.12 5.58 5.6 7.88.17.83.35 1.68.75 2.6 1.2 2.75 7.55 6.6 9.67 7.82.42.24.68.38.69.39Z"
    />
    <path
      fill="#C8D0D7"
      d="M38.78 45.52c-.57-.31-1.15-.58-1.72-.81C27.6 41.98 7.14 59.3 0 57.91l47.76 27.75c7.38 1.44 29.04-17.16 38-12.84L38.77 45.51l.01.01Z"
    />
    <path fill="#B29A6F" d="m85.77 72.83.77.45c-.24-.17-.5-.32-.77-.45Z" />
    <path fill="#A9D7FF" d="m86.539 73.28-7.57-4.4h.01c2.09-.08 3.87.14 5.21.91l.06.03s.04.03.07.04c1.12.69 1.89 1.77 2.22 3.41v.01Z" />
    <path fill="#F1F1F1" d="M84.32 69.86s-.04-.03-.07-.04l.07.04Z" />
    <path
      fill="#A9D7FF"
      d="M51.561 69.39 3.991 41.57h-.01l-1.21-.72h-.01a10.33 10.33 0 0 1-2.48-2.38l4.01 2.33 3.33 1.93 40.74 23.68c.91 1.28 2 2.25 3.21 2.98h-.01Z"
    />
    <path
      fill="#FDFCFC"
      d="m36.162 41.73-.37-.22c-1.32-.71-3.07-.91-5.09-.84-5.05.18-11.83 2.11-17.98 2.36-1.78.07-3.5 0-5.12-.29l40.74 23.68c.91 1.28 2 2.25 3.21 2.98l.03.02c7.33 4.33 19.47-.25 27.38-.53h.01c2.09-.08 3.87.14 5.21.91l-48.03-28.06.01-.01Z"
    />
  </svg>
);
export default Religiosos;
