import { SVGProps } from "react";
const Consumibles = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={115} height={143} fill="none" {...props}>
    <path
      fill="#78BCFB"
      d="M19.384 136.358c-6.819-3.859 83.294-3.872 76.588.298-4.501 3.023-12.667 6.365-38.301 6.344-27.713-.023-32.678-3.376-38.287-6.642Z"
    />
    <path fill="#59AFFF" d="M0 129.647c1.07-15.415 113.994-15.292 114.97.097C113.9 145.158.976 145.035 0 129.647Z" />
    <path fill="#78BCFB" d="M1.406 129.089c1.044-15.036 111.19-14.916 112.142.094-1.044 15.035-111.19 14.916-112.142-.094Z" />
    <path fill="#78BCFB" d="M14.75 130.489c.393-12.905 85.031-12.814 85.491-.853-1.738 13.181-83.447 14.12-85.491.853Z" />
    <path fill="#78BCFB" d="M20.414 131.855c.486-10.881 73.742-10.588 74.142-.451-1.544 11.093-72.47 11.694-74.142.451Z" />
    <path
      fill="#B5DBFF"
      d="M19.392 132.243c-6.819-3.86 83.294-3.873 76.588.297-4.501 3.024-12.667 6.366-38.301 6.344-27.713-.022-32.678-3.376-38.287-6.641Z"
    />
    <path fill="#59AFFF" d="M.008 125.532c1.07-15.415 113.994-15.293 114.97.096-1.07 15.414-113.994 15.292-114.97-.096Z" />
    <path fill="#78BCFB" d="M1.426 124.973c1.044-15.036 111.189-14.916 112.141.094-1.044 15.035-111.19 14.916-112.141-.094Z" />
    <path fill="#78BCFB" d="M14.758 126.373c.393-12.905 85.031-12.813 85.491-.853-1.737 13.181-83.447 14.12-85.491.853Z" />
    <path fill="#78BCFB" d="M20.422 127.74c.485-10.881 73.742-10.588 74.142-.451-1.544 11.092-72.471 11.693-74.142.451Z" />
    <path
      fill="#B5DBFF"
      d="M19.392 128.127c-6.819-3.859 83.294-3.872 76.589.298-4.502 3.023-12.668 6.365-38.302 6.344-27.713-.024-32.678-3.376-38.287-6.642Z"
    />
    <path fill="#59AFFF" d="M.008 121.416c1.07-15.415 113.994-15.293 114.97.096-1.07 15.415-113.994 15.292-114.97-.096Z" />
    <path fill="#78BCFB" d="M1.426 120.858c1.044-15.036 111.189-14.917 112.141.094-1.044 15.035-111.19 14.915-112.141-.094Z" />
    <path fill="#78BCFB" d="M14.75 122.258c.393-12.905 85.031-12.814 85.491-.853-1.738 13.18-83.447 14.12-85.491.853Z" />
    <path fill="#78BCFB" d="M20.422 123.624c.485-10.881 73.742-10.588 74.142-.451-1.544 11.092-72.471 11.694-74.142.451Z" />
    <path
      fill="#B5DBFF"
      d="M19.404 124.012c-6.819-3.86 83.293-3.873 76.588.297-4.501 3.023-12.667 6.366-38.301 6.344-27.713-.023-32.678-3.376-38.287-6.641Z"
    />
    <path fill="#59AFFF" d="M.02 117.3c1.07-15.414 113.994-15.292 114.97.097C113.92 132.811.995 132.689.02 117.3Z" />
    <path fill="#78BCFB" d="M1.438 116.742c1.043-15.036 111.189-14.916 112.141.094-1.044 15.035-111.19 14.916-112.141-.094Z" />
    <path fill="#78BCFB" d="M14.77 118.142c.393-12.905 85.031-12.814 85.49-.853-1.737 13.181-83.446 14.12-85.49.853Z" />
    <path fill="#78BCFB" d="M20.434 119.509c.485-10.881 73.742-10.588 74.142-.451-1.544 11.092-72.471 11.693-74.142.451Z" />
    <path
      fill="#B5DBFF"
      d="M19.404 119.896c-6.819-3.859 83.293-3.872 76.588.298-4.502 3.023-12.668 6.365-38.302 6.344-27.712-.024-32.677-3.376-38.286-6.642Z"
    />
    <path fill="#59AFFF" d="M.02 113.185c1.07-15.415 113.994-15.293 114.97.096C113.92 128.695.995 128.573.02 113.185Z" />
    <path fill="#78BCFB" d="M1.426 112.626c1.044-15.035 111.189-14.916 112.141.094-1.044 15.035-111.19 14.916-112.141-.094Z" />
    <path fill="#78BCFB" d="M14.77 114.026c.393-12.905 85.031-12.813 85.49-.853-1.737 13.181-83.446 14.12-85.49.853Z" />
    <path fill="#78BCFB" d="M20.434 115.393c.485-10.881 73.742-10.588 74.142-.451-1.544 11.092-72.471 11.694-74.142.451Z" />
    <path
      fill="#B5DBFF"
      d="M19.416 115.78c-6.819-3.86 83.293-3.872 76.587.297-4.5 3.024-12.667 6.366-38.3 6.345-27.714-.024-32.679-3.376-38.288-6.642Z"
    />
    <path fill="#59AFFF" d="M.031 109.069c1.07-15.415 113.995-15.292 114.97.097-1.07 15.414-113.994 15.291-114.97-.097Z" />
    <path fill="#B5DBFF" d="M1.45 108.511c1.043-15.036 111.189-14.917 112.141.094-1.044 15.035-111.19 14.915-112.142-.094Z" />
    <path fill="#78BCFB" d="M14.781 109.911c.393-12.906 85.032-12.814 85.491-.853-1.737 13.18-83.447 14.12-85.49.853Z" />
    <path fill="#B5DBFF" d="M20.445 111.277c.486-10.881 73.743-10.588 74.143-.451-1.545 11.093-72.472 11.694-74.143.451Z" />
    <path fill="#FCFCFC" d="M95.59 103.053s17.203 3.622 6.535 9.31c4.417-1.791 6.675-3.263 5.552-4.919-1.623-2.39-7.42-3.538-12.087-4.392v.001Z" />
    <path
      fill="#C2E6F3"
      d="m59.298 106.097-23.255.191c.037 4.585 7.074 8.819 12.993 9.937 3.536.496 6.933.697 10.35.671a70.07 70.07 0 0 0 10.337-.84c5.9-1.215 12.868-5.563 12.831-10.149l-23.255.19Z"
    />
    <path fill="#FCFCFC" d="M17.195 79.134c.626 47.576 83.895 46.862 83.774-.685l-83.774.685Z" />
    <path fill="#78BCFB" d="M21.395 79.1c.603 19.203 10.182 35.683 38.294 35.279 26.781-.384 40.323-16.713 40.618-35.924l-78.912.645Z" />
    <path fill="#FCFCFC" d="M100.971 78.45c.299-5.904-84.24-5.194-83.774.684-.3 5.903 84.239 5.193 83.774-.685Z" />
    <path fill="#78BCFB" d="M99.803 78.174c.639-4.832-82.285-4.139-81.5.666-.64 4.83 82.284 4.137 81.5-.666Z" />
    <path fill="#FCFCFC" d="m89.424 84.112 6.171-1.08s.6 15.276-20.052 26.639c14.813-11.944 13.88-25.559 13.88-25.559Z" />
    <path
      fill="#C2E6F3"
      d="m59.81 93.05-23.244.76c.148 4.583 7.285 8.643 13.23 9.617 3.546.408 6.947.526 10.362.417a70.019 70.019 0 0 0 10.314-1.092c5.87-1.36 12.73-5.877 12.583-10.46l-23.244.758Z"
    />
    <path fill="#FCFCFC" d="M17.063 67.126c1.773 47.546 84.999 44.796 83.732-2.734l-83.733 2.734Z" />
    <path fill="#78BCFB" d="M21.262 66.989c1.067 19.183 11.04 35.423 39.134 34.332 26.764-1.04 39.908-17.695 39.739-36.907l-78.873 2.575Z" />
    <path fill="#FCFCFC" d="M100.798 64.391c.157-5.908-84.34-3.13-83.732 2.735-.157 5.908 84.339 3.13 83.732-2.735Z" />
    <path fill="#78BCFB" d="M99.632 64.144c.521-4.845-82.361-2.123-81.46 2.661-.523 4.845 82.36 2.123 81.46-2.66Z" />
    <path fill="#FCFCFC" d="m89.393 70.335 6.144-1.23s.967 15.256-19.404 27.121c14.52-12.302 13.26-25.89 13.26-25.89v-.001Z" />
    <path
      fill="#C2E6F3"
      d="m59.175 79.522-23.253.393c.076 4.585 7.15 8.757 13.078 9.825 3.54.464 6.938.636 10.355.58a70.077 70.077 0 0 0 10.33-.93c5.889-1.267 12.819-5.675 12.743-10.26l-23.253.393Z"
    />
    <path fill="#FCFCFC" d="M16.828 52.927c1.035 47.568 84.294 46.13 83.765-1.415l-83.765 1.415Z" />
    <path fill="#78BCFB" d="M21.028 52.856c.769 19.198 10.489 35.592 38.596 34.944C86.4 87.182 99.802 70.736 99.93 51.523l-78.904 1.333Z" />
    <path fill="#FCFCFC" d="M100.595 51.512c.249-5.906-84.28-4.459-83.764 1.415-.25 5.905 84.28 4.458 83.764-1.415Z" />
    <path fill="#78BCFB" d="M99.427 51.246c.597-4.836-82.317-3.42-81.491 1.377-.598 4.836 82.317 3.42 81.491-1.377Z" />
    <path fill="#FCFCFC" d="m89.09 57.275 6.163-1.134s.73 15.27-19.823 26.812c14.71-12.072 13.66-25.678 13.66-25.678Z" />
    <path
      fill="#78BCFB"
      d="M81.64 46.906c-1.104 9.398-4.784 17.502-9.706 22.692-1.492 1.555-2.86 3.364-4.153 5.225-.306.36-1.163.713-2.252 1.038-.731.218-1.463.392-2.196.523l-10.985-1.31c-1.196-.52-3.329-1.63-3.495-2.028-.876-2.155-1.073-3.084-1.793-4.7-2.963-6.664-3.727-27.189-3.64-27.764l38.394 4.576c-.047.58-.105 1.163-.174 1.749Z"
    />
    <path
      fill="#78BCFB"
      d="M81.646 46.906c-1.104 9.398-4.785 17.501-9.706 22.692-1.474 1.542-2.835 3.318-4.109 5.167-.333.439-1.16.742-2.297 1.096-.73.217-1.463.392-2.195.523l-10.986-1.31c-1.196-.52-3.328-1.63-3.495-2.028a5.832 5.832 0 0 1-.417-.873c-.768-2.017-1.656-3.929-2.676-5.695-3.833-6.469-5.482-16.555-3.943-26.087l39.998 4.767c-.047.58-.105 1.163-.174 1.749Z"
    />
    <path
      fill="#78BCFB"
      d="M80.895 46.817c-1.104 9.398-4.785 17.502-9.706 22.692-1.4 1.478-2.146 3.476-3.36 5.256-.333.44-1.16.742-2.297 1.096a20.08 20.08 0 0 1-2.196.523c-3.989.722-8.027.144-11.686-1.632-1.407-.68-2.562-1.326-2.795-1.705-.876-2.154-.831-3.041-1.551-4.657-2.963-6.664-4.125-25.993-4.117-26.066.07-.585.148-1.166.237-1.742l37.644 4.487c-.047.58-.105 1.163-.174 1.749h.001Z"
    />
    <path fill="#FCFCFC" d="M81.823 45.157c.277-4.917-39.185-9.61-40.029-4.77-.277 4.917 39.185 9.609 40.03 4.77Z" />
    <path fill="#78BCFB" d="M80.985 44.85c.293-4.094-37.53-8.591-38.167-4.548-.293 4.095 37.53 8.592 38.167 4.549Z" />
    <path fill="#FCFCFC" d="M40.243 30.387c-1.21-4.772 36.622-17.013 38.373-12.427 1.21 4.772-36.621 17.013-38.373 12.427Z" />
    <path fill="#78BCFB" d="M41.008 29.924c-1.07-3.962 35.192-15.694 36.588-11.85 1.068 3.962-35.193 15.695-36.589 11.85Z" />
    <path
      fill="#78BCFB"
      d="M44.39 10.15c-7.415-4.298-14.617-1.928-15.139 3.232-.577 5.696 4.086 12.055 12.1 15.064 7.286 2.735 12.545.095 13.688-4.946 1.143-5.042-3.961-9.474-10.65-13.35Zm6.88 13.211c-.857 3.782-5.347 3.216-11.232 1.784-5.724-1.394-9.627-7.31-8.77-11.091.858-3.782 6.106-3.107 11.419.56 5.23 3.609 9.441 4.965 8.583 8.747Z"
    />
    <path
      fill="#699DCD"
      d="M44.686 10.3c-7.709-4.05-14.615-1.926-15.138 3.234-.577 5.695 4.086 12.054 12.1 15.063 7.286 2.735 12.545.095 13.688-4.946 1.143-5.041-3.808-9.755-10.65-13.35Zm7.84 13.724c-.92 4.058-3.244 2.55-9.776 2.248-7.752-.358-12.633-8.19-11.713-12.247.92-4.059 5.886-3.534 11.82-.772 5.934 2.761 10.59 6.713 9.669 10.771Z"
    />
    <path fill="#FCFCFC" d="M77.615 3.507c-.342-4.914-40.076-4.545-40.306.363.342 4.913 40.076 4.544 40.306-.363Z" />
    <path fill="#78BCFB" d="M76.748 3.31c-.223-4.1-38.307-3.746-38.432.345.224 4.1 38.308 3.746 38.432-.346Z" />
    <path
      fill="#78BCFB"
      d="M77.621 3.596v.003c.026.581-.174 1.033-.17 1.622.085 9.463-.574 15.483-6.563 23.877-1.248 1.733-2.396 3.65-3.427 5.646v.001l-.001.002v.002c-.002 0-.002.001-.002.002a3.82 3.82 0 0 1-.034.058c-.597.819-2.987 1.72-4.217 2.116l-11.062.098c-1.26-.365-3.508-1.191-3.727-1.574a5.934 5.934 0 0 1-.517-.806c-1.016-1.903-2.137-3.686-3.37-5.308-4.613-5.928-7.516-15.724-7.185-25.376l1.018-.01c2.695 4.07 36.945 3.305 38.348-.345l.91-.009h-.001Z"
    />
    <path
      fill="#699DCD"
      d="M77.665 4.934c-.14 6.267.329 13.526-5.457 16.574-5.536 3.156-19.802 7.014-24.429 7.513-3.163.34-6.221-5.946-7.568-10.143-1.77-5.513-2.06-14.222-2.055-14.517 4.135 3.043 36.124 3.286 38.55-.757l.156-.002c.016.332.746-.34.754-.003.007.25.056 1.082.049 1.335Z"
    />
    <path
      fill="#FCFCFC"
      d="M77.59 3.391a.678.678 0 0 1 .032.208c.002.346-.248.683-.72 1.006-4.705 3.564-32.135 3.782-37.956.813-1.015-.445-1.771-.942-1.602-1.665l.977-.009c.02.34.26.55.628.787 2.165 1.328 9.683 2.254 18.616 2.173 10.3-.19 18.667-1.275 19.187-3.306l.838-.007ZM75.37 7.532l-4.087 1.035s2.451 7.842-.404 12.137c5.59-4.135 4.49-13.172 4.49-13.172Z"
    />
    <path
      fill="#699DCD"
      d="M89.71 24.646c-2.108-4.732-9.793-4.77-15.845 1.517-5.372 5.58-8.598 11.624-5.938 16.048 2.66 4.423 8.476 5.263 14.537.358 6.667-5.396 9.572-12.7 7.246-17.923Zm-7.03 14.372c-5.14 3.225-9.224 5.185-11.22 1.867-1.995-3.319 1.578-5.94 5.416-11.024 3.897-5.163 8.667-7.468 10.663-4.15 1.995 3.319.139 10.17-4.86 13.307Z"
    />
    <path
      fill="#78BCFB"
      d="M87.739 25.613c-2.142-3.56-6.691-1.488-11.462 3.013-4.77 4.501-7.953 9.727-5.812 13.288 2.141 3.56 3.876 1.393 9.982-.963 7.246-2.797 9.432-11.777 7.291-15.338Zm-5.062 13.405c-5.139 3.225-9.224 5.185-11.219 1.867-1.995-3.319 1.577-5.94 5.415-11.024 3.898-5.163 8.668-7.468 10.663-4.15 1.996 3.319.14 10.17-4.859 13.307Z"
    />
    <path
      fill="#78BCFB"
      d="m40.258 30.476.001.003c2.537 9.316 8.201 17.791 14.357 22.071 1.742 1.227 3.414 2.706 5.017 4.273h.001l.001.002s.002 0 .002.002h.002l.05.046c.822.588 3.372.685 4.664.671l10.53-3.411c1.082-.747 2.958-2.242 3.047-2.676.102-.293.182-.604.239-.929.369-2.128.876-4.175 1.54-6.106 2.527-7.089 2.222-17.307-1.108-26.364l-.969.313c-1.287 4.717-34.036 14.844-36.508 11.824l-.866.28Z"
    />
    <path
      fill="#699DCD"
      d="M40.973 30.244c4.375 19.122 38.115 16.183 38.733 14.178 4.083-11.87-1.012-26.094-1.043-26.182-.09-.282-1.31 1.149-1.407.87C74.67 23.13 44.1 33.262 41.121 30.196l-.148.048Z"
    />
    <path
      fill="#FCFCFC"
      d="M40.223 30.272a.679.679 0 0 0 .034.207c.106.329.45.569.997.726 5.58 1.892 31.685-6.593 36.283-11.255.825-.745 1.387-1.456 1-2.088l-.93.301c.087.328-.074.604-.35.946-1.64 1.946-8.487 5.208-16.991 7.961-9.837 3.085-18.118 4.706-19.246 2.943l-.797.259ZM76.492 43.144s4.18-15.103-2.168-18.97c3.581-2.073 2.794-1.99 2.794-1.99s5.638 9.934-.626 20.96Z"
    />
    <path
      fill="#699DCD"
      d="M48.516 46.953c-6.471-4.083-13.15-3.456-14.916 1.4-1.767 4.856 2.047 12.1 8.518 16.182 6.471 4.083 13.15 3.456 14.916-1.4 1.767-4.856-2.047-12.1-8.518-16.182Zm7.84 15.951c-1.326 3.643-5.708 2.51-11.367.34-5.504-2.112-10.825-7.658-8.92-12.863 1.544-4.22 8.059-1.56 12.869 2.753 4.736 4.246 8.742 6.128 7.417 9.77Z"
    />
    <path
      fill="#78BCFB"
      d="M48.412 48.595c-7.14-4.998-12.913-4.592-14.234.421-1.342 5.101.952 12.159 8.524 16.163 6.884 3.641 12.432 1.692 14.198-3.164 1.767-4.855-2.151-8.983-8.488-13.42Zm6.842 14.31c-1.325 3.642-5.707 2.509-11.366.338-5.504-2.11-8.633-8.476-7.307-12.118 1.325-3.642 6.446-2.304 11.256 2.009 4.736 4.246 8.742 6.128 7.417 9.77Z"
    />
    <path
      fill="#78BCFB"
      d="M48.172 51.807c-5.54-3.495-10.4-4.648-11.822-.739-1.422 3.908 2.436 12.298 10.081 13.64 6.442 1.132 8.558 2.923 9.98-.985 1.422-3.908-2.7-8.421-8.24-11.916Zm7.069 11.097c-1.325 3.643-5.708 2.51-11.367.34-5.503-2.112-8.632-8.477-7.307-12.119 1.325-3.642 6.447-2.304 11.257 2.009 4.736 4.246 8.742 6.128 7.417 9.77Z"
    />
    <path
      fill="#78BCFB"
      d="M81.809 45.146v.004c-.713 9.632-4.655 19.047-9.88 24.438-1.475 1.54-2.833 3.316-4.108 5.164v.001l-.001.001-.001.003-.002.001-.041.054c-.694.736-3.178 1.325-4.449 1.562l-10.985-1.31c-1.204-.524-3.33-1.629-3.5-2.037a5.993 5.993 0 0 1-.412-.865c-.768-2.017-1.657-3.928-2.676-5.695C41.92 60 40.27 49.913 41.81 40.38l1.01.12c2.164 4.38 36.235 7.982 38.085 4.539l.903.107h.001Z"
    />
    <path
      fill="#699DCD"
      d="M81.062 45.058c-.027.33.788-.235.753.1-.025.249-.137 1.498-.174 1.748-.22 1.494-.216 2.277-.575 3.68-1.584 6.206-10.526 3.697-23.123 4.343-3.35.171-10.274.717-13.12-2.476-1.904-2.135-3.038-7.121-2.62-11.16-.004.054.006-.055 0 0 .007-.055.974.46.982.387.012-.094.018.445.03.352.035-.295.074-.588.115-.88 3.305 3.445 35.238 7.472 37.577 3.887l.154.018h.001Z"
    />
    <path
      fill="#FCFCFC"
      d="M81.8 44.94a.68.68 0 0 1 .007.21c-.041.343-.332.646-.84.906-5.115 2.937-32.353-.34-37.754-4.024-.952-.572-1.64-1.16-1.38-1.856l.97.116c-.023.339.188.578.524.86 1.982 1.593 9.323 3.469 18.194 4.525 10.241 1.124 18.678 1.113 19.448-.836l.831.1Z"
    />
    <path
      fill="#C2E6F3"
      d="m59.17 79.532-23.252.393c.076 4.586 7.15 8.758 13.078 9.825 3.54.464 6.938.636 10.355.58a70.065 70.065 0 0 0 10.33-.93c5.89-1.266 12.819-5.675 12.743-10.26l-23.253.393Z"
    />
    <path fill="#78BCFB" d="M100.597 51.523c.513 47.575-82.748 48.951-83.765 1.414l1.225-.02c2.177 4.405 79.276 3.09 81.255-1.372l1.285-.022Z" />
    <path
      fill="#699DCD"
      d="M100.591 51.553c.033 3.194-.502 7.283-1.328 9.738-2.247 6.68-29.011 6.77-41.963 6.841-8.479.047-38.274 2.365-38.25-13.777 6.849 1.389 23.227 2.692 39.734 2.413 20.527-.347 38.257-3.042 41.806-5.215h.001Z"
    />
    <path fill="#FCFCFC" d="m95.263 55.22 3.378-.622s1.293 6.592-4.063 8.648c2.126-3.57.685-8.026.685-8.026Z" />
    <path fill="#FCFCFC" d="M100.593 51.523c.445 5.893-84.074 7.303-83.764 1.414l1.224-.02c2.178 4.405 79.277 3.09 81.256-1.372l1.284-.022Z" />
    <path
      fill="#C2E6F3"
      d="m59.81 93.05-23.244.76c.148 4.583 7.285 8.643 13.23 9.617 3.546.408 6.947.526 10.362.417a70.154 70.154 0 0 0 10.314-1.092c5.87-1.36 12.73-5.877 12.583-10.46l-23.244.758Z"
    />
    <path
      fill="#78BCFB"
      d="M100.794 64.391c1.252 47.562-81.976 50.25-83.731 2.734l1.224-.04c2.245 4.37 79.315 1.842 81.223-2.652l1.285-.042h-.001Z"
    />
    <path
      fill="#699DCD"
      d="M100.797 64.422c0 3.85-.505 6.827-1.168 9.727-1.174 5.138-16.204 7.198-39.818 6.752-27.129-.511-40.205-.044-41.561-12.752 6.869 1.28 24.326 2.684 40.827 2.146 20.52-.67 38.205-3.645 41.72-5.873Z"
    />
    <path fill="#FCFCFC" d="m95.534 68.15 3.19-.706s.516 6.044-4.068 8.748c2.084-3.298.878-8.041.878-8.041Z" />
    <path fill="#FCFCFC" d="M100.795 64.391c.535 5.886-83.951 8.627-83.732 2.734l1.224-.04c2.246 4.37 79.315 1.842 81.224-2.652l1.284-.042Z" />
    <path
      fill="#78BCFB"
      d="m59.302 106.103-23.255.19c.037 4.585 7.074 8.819 12.993 9.938 3.536.495 6.933.696 10.35.67 3.416-.03 6.81-.287 10.337-.839 5.9-1.216 12.868-5.564 12.831-10.15l-23.255.191Z"
    />
    <path fill="#78BCFB" d="M100.973 78.455c.104 47.578-83.165 48.229-83.774.684l1.225-.01c2.14 4.425 79.247 3.782 81.264-.663l1.285-.01v-.001Z" />
    <path
      fill="#699DCD"
      d="M100.969 78.485c-1.031 21.901-16.919 35.459-41.283 35.9-27.13.389-38.073-15.194-39.272-33.528 6.836 1.448 22.196 2.614 38.705 2.479 20.529-.168 38.281-2.709 41.849-4.85h.001Z"
    />
    <path
      fill="#FCFCFC"
      d="m91.533 83.986 6.172-1.08s.599 15.276-20.053 26.638c14.814-11.943 13.881-25.558 13.881-25.558ZM100.97 78.455c.393 5.897-84.135 6.57-83.773.684l1.224-.01c2.14 4.425 79.247 3.782 81.264-.663l1.285-.01v-.001ZM83.496 22.483s5.296-1.779 5.523 3.326c-.94-2.653-2.02-3.367-5.523-3.326Z"
    />
  </svg>
);
export default Consumibles;
