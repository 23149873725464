export enum SystemRolesEnum {
  SUPERNAP = "supernap",
  SUPERADMIN = "superadmin",
  ADMIN = "admin",
  ACCOUNTANT = "accountant",
  USER = "usuario",
}

export enum SystemModulesEnum {
  ACCOUNTING = "CONTABILIDAD",
  ACCOUNTS = "CUENTAS",
  INVENTORY = "INVENTARIO",
  INCOME = "RECIBOS DE APORTES",
  COLLABORATORS = "COLABORADORES",
  COLLABORATORS_PAYROLLS = "PLANILLAS",
  CLAIMS = "RECLAMOS",
  INCIDENTS = "INCIDENCIAS",
  SETTING = "CONFIGURACIÓN",
  MESSAGES = "MENSAJES",
  OCCUPATIONAL_HEALTH = "SEGURIDAD Y SALUD OCUPACIONAL",
  SEXUAL_HARASSMENT = "PREVENCIÓN AL HOSTIGAMIENTO SEXUAL",
  REGISTER_ASSOCIATES = "REGISTRO DE ASOCIADOS",
  BINGO = "BINGO",
}

export enum CommitteeKindEnum {
  GENERAL = "general",
  SEXUAL_HARASSMENT = "sexual_harassment",
}
