export enum TypeAttendanceSignEnum {
  START = "startAt",
  END = "endAt",
}

export enum WeekDaysEnum {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export enum WorkerAttendanceImportTypes {
  IMPORT = "import",
  APPLICATION = "application",
  MANUAL = "manual",
  ZKTECO = "zkteco",
}

export enum WeeklyScheduleRangeTypeEnum {
  NORMAL = "normal",
  RANGE = "range",
  FREE = "free",
}

export enum WorkerAttendancePermitStatusEnum {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum AttendancePermitsTypeEnum {
  ABSENCE = "absence",
  DELAY = "delay",
}
