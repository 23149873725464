import { SVGProps } from "react";

const Equipo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={128} height={83} fill="none" {...props}>
    <path
      fill="#94D8ED"
      d="M115.092 0H12.903c-1.517 0-2.747 1.074-2.747 2.4v60.744c0 1.325 1.23 2.399 2.747 2.399h102.189c1.517 0 2.747-1.074 2.747-2.4V2.4c0-1.325-1.23-2.399-2.747-2.399Z"
    />
    <path
      fill="#D6D9EA"
      d="M115.092 0H12.903c-1.514 0-2.747 1.076-2.747 2.4v.034h100.377c1.514 0 2.747 1.077 2.747 2.4v60.702h1.804c1.515 0 2.747-1.076 2.747-2.4V2.4c0-1.323-1.232-2.399-2.747-2.399h.008Z"
      opacity={0.65}
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#C1EFFD"
      d="M125.221 82.956H2.779C1.25 82.956 0 81.802 0 80.374l9.232-14c.75-1.133 2.079-1.83 3.512-1.83h102.004c1.394 0 2.699.661 3.456 1.752L128 80.374c0 1.428-1.241 2.582-2.779 2.582Z"
    />
    <path
      fill="#94D8ED"
      d="M125.221 78.995H2.779c-.58 0-1.12-.169-1.57-.45L0 80.38c0 1.428 1.24 2.582 2.78 2.582H125.22c1.53 0 2.779-1.154 2.779-2.582l-1.257-1.808a2.9 2.9 0 0 1-1.522.422Z"
    />
    <path fill="#4B6E88" d="M112.642 5.614H15.367v54.314h97.275V5.614Z" />
    <path fill="#1D6D93" d="M112.642 57.206H15.367v2.723h97.275v-2.723Z" />
    <path fill="#404040" d="M109.25 9.33h-90.5v46.884h90.5V9.33Z" />
    <path fill="#BFBFBF" d="M91.185 16.182H34.496v33.18h56.69v-33.18Z" />
    <path fill="#fff" d="M75.744 22.922H49.941v19.692h25.803V22.922Z" />
    <path fill="#303030" d="M109.245 12.713H93.891V52.83h15.354V12.713Z" />
    <path
      fill="#404040"
      d="M107.466 13.951H95.672v.992h11.794v-.992ZM106.741 16.428h-11.07v.992h11.07v-.992ZM106.741 18.904h-11.07v.993h11.07v-.992ZM107.464 21.38H94.945v.993h12.519v-.992ZM107.471 23.857H96.418v.992h11.053v-.992ZM106.741 26.334h-11.07v.992h11.07v-.992ZM106.741 28.81h-11.07v.992h11.07v-.992ZM107.464 31.287H94.945v.992h12.519v-.992ZM107.471 33.764H96.418v.992h11.053v-.992Z"
    />
    <path fill="#303030" d="M25.581 14.24H18.75v37.147h6.831V14.24Z" />
    <path
      fill="#BFBFBF"
      d="M21.952 15.908h-1.386v1.118h1.386v-1.118ZM24.265 15.908h-1.386v1.118h1.386v-1.118ZM21.952 18.384h-1.386v1.118h1.386v-1.118ZM24.265 18.384h-1.386v1.118h1.386v-1.118ZM21.952 20.86h-1.386v1.119h1.386V20.86ZM24.265 20.86h-1.386v1.119h1.386V20.86ZM21.952 23.337h-1.386v1.118h1.386v-1.118ZM24.265 23.337h-1.386v1.118h1.386v-1.118ZM21.952 25.814h-1.386v1.118h1.386v-1.118ZM24.265 25.814h-1.386v1.118h1.386v-1.118ZM21.952 28.29h-1.386v1.118h1.386V28.29ZM24.265 28.29h-1.386v1.118h1.386V28.29Z"
    />
    <path fill="#303030" d="M93.6 12.713H78.247v7.964h15.355v-7.964Z" />
    <path fill="#6E7300" d="M93.014 19.256H78.828v.823h14.186v-.823Z" />
    <path fill="#EFFF2E" d="M93.025 13.642h-6.912v.162h6.912v-.162Z" />
    <path fill="#00E702" d="M93.025 14.134h-6.912v.162h6.912v-.162Z" />
    <path fill="#D02D00" d="M93.025 14.634h-6.912v.162h6.912v-.162Z" />
    <path fill="#85C3E0" d="M93.025 15.127h-6.912v.161h6.912v-.162Z" />
    <path fill="#8BFBFF" d="M93.025 15.619h-6.912v.162h6.912v-.162Z" />
    <path fill="#E700D8" d="M93.025 16.118h-6.912v.162h6.912v-.162Z" />
    <path fill="#17002E" d="M93.025 16.611h-6.912v.162h6.912v-.162Z" />
    <path fill="#303030" d="M93.6 43.416H78.247v7.965h15.355v-7.965Z" />
    <path fill="#C04726" d="M80.595 49.488h-.958v.788h.958v-.788Z" />
    <path fill="#6E7300" d="M81.755 49.488h-.958v.788h.959v-.788Z" />
    <path fill="#8A900E" d="M82.924 49.488h-.96v.788h.96v-.788Z" />
    <path fill="#B8C013" d="M84.084 49.488h-.959v.788h.959v-.788Z" />
    <path fill="#C6CC42" d="M85.236 49.488h-.959v.788h.959v-.788Z" />
    <path fill="#D4D971" d="M86.404 49.488h-.959v.788h.959v-.788Z" />
    <path fill="#E3E6A1" d="M87.564 49.488h-.958v.788h.958v-.788Z" />
    <path fill="#85C3E0" d="M88.713 49.488h-.96v.788h.96v-.788Z" />
    <path fill="#0A4660" d="M89.865 49.488h-.959v.788h.959v-.788Z" />
    <path fill="#0E6990" d="M91.037 49.488h-.959v.788h.959v-.788Z" />
    <path fill="#138BC0" d="M92.197 49.488h-.959v.788h.959v-.788Z" />
    <path fill="#39BCF3" d="M93.353 49.488h-.959v.788h.96v-.788Z" />
    <path fill="#6B6D75" d="m117.772 74.942-5.349-9.54h-96.84l-5.349 9.54h35.978l-.37 1.977h36.323l-.37-1.977h35.977Z" />
    <path
      fill="#404040"
      d="M63.937 2.476c0 .64-.604 1.154-1.353 1.154-.75 0-1.354-.52-1.354-1.154 0-.633.605-1.153 1.354-1.153.75 0 1.353.52 1.353 1.153Z"
    />
    <path
      stroke="#D6D9EA"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="m82.262 75.09-36.211.007M17.884 65.156l-4.552 10.173M24.84 65.156l-4.559 10.173M31.81 65.156l-4.56 10.173M114.41 68.062l-100.57-.52M115.736 70.44l-103.228-.317M117.17 72.958l-106.08-.4M38.763 65.156 34.21 75.329M45.731 65.156l-4.56 10.173M51.817 65.022l-3.505 10.005M57.755 64.84 56.16 75.33M63.877 65.015l-.338 10.18M76.027 65.022l3.61 10.125M69.953 64.853l1.845 10.462M110.152 65.156l4.56 10.173M103.191 65.156l4.56 10.173M96.238 65.156l4.552 10.173M89.277 65.156l4.56 10.173M82.32 65.156l4.552 10.173"
    />
    <path
      fill="#fff"
      d="M59.924 34.6c0 1.661-1.58 3.005-3.52 3.005-1.942 0-3.521-1.344-3.521-3.004s1.579-3.005 3.52-3.005c1.942 0 3.52 1.344 3.52 3.005Z"
    />
    <path fill="#303030" d="M17.757 59.429c.485 0 .878-.334.878-.746s-.393-.745-.878-.745-.878.333-.878.745.393.746.878.746Z" />
    <path fill="#4FB900" d="M22.639 58.683c0 .415-.395.746-.878.746s-.878-.338-.878-.746.395-.745.878-.745.878.337.878.745Z" />
    <path fill="#D02D00" d="M26.631 58.683c0 .415-.395.746-.878.746s-.878-.338-.878-.746.395-.745.878-.745.878.337.878.745Z" />
    <path
      fill="#fff"
      d="M28.716 5.614 15.367 17.413V28.31L41.041 5.614H28.716ZM112.642 32.44 81.562 59.93h12.946l18.134-16.041V32.44ZM73.611 5.614 15.367 52.288v7.64h16.684L99.825 5.614H73.611Z"
      opacity={0.08}
    />
  </svg>
);
export default Equipo;
