import numeral from "numeral";

const napformat = (value: number | undefined | string) => numeral(value).format("0,0.00");

export const numberReport = (value: string | undefined | number | null | unknown) => {
  return typeof value === "number" ? napformat(value) : "";
};

export const valItem = (item: number) => (item > 0 ? napformat(item) : "");

export default napformat;
