const Page401 = () => {
  return (
    <div>
      <h1>
        NO AUTORIZADO <br />
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae, consequuntur dolor eum soluta in illum magni ad, nostrum eligendi cum dolorum,
        aspernatur nemo corporis ipsum.
      </h1>
    </div>
  );
};

export default Page401;
