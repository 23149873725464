import { useEffect, useState } from "react";
import { notification } from "antd";
import { getTypeCoins } from "requests/accounting";
import { TypeCoinsProps } from "interfaces/vouchers";

const useCoins = (active = false) => {
  const [coins, setCoins] = useState<Array<TypeCoinsProps>>([]);
  const [load, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (active) getCoins();
    // eslint-disable-next-line
  }, []);

  const getCoins = async () => {
    try {
      setLoading(true);
      const res = await getTypeCoins();
      setCoins([...res]);
    } catch (error) {
      notification.error({ message: "Ocurrió un error al obtener las monedas.", description: "La causa mas probable es tu conexión a internet." });
    } finally {
      setLoading(false);
    }
  };

  return { coins, load, getCoins };
};

export default useCoins;
