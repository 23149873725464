import { SVGProps } from "react";
const Edificaciones = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={116} height={101} fill="none" {...props}>
    <path fill="#F7F1F1" d="m56.267 100.576.092-35.747L2.5 37.776v33.716l53.767 29.084Z" />
    <path fill="#CAC4C4" d="m56.262 99.203 52.474-29.455V30.283l-23.91-1.43-28.564 35.92v34.43Z" />
    <path fill="#5C5F61" d="M56.262 98.69v2.001l54.691-29.542v-1.685L56.262 98.69Z" />
    <path fill="#7B8083" d="M56.264 98.69v2.001L1.082 70.837v-1.775L56.264 98.69Z" />
    <path fill="#AEB1BE" d="M2.497 69.12v-.857l-1.415.8L56.264 98.69v-.802L2.497 69.12Z" />
    <path fill="#7A7D8A" d="M108.86 68.861v.775L56.262 97.888v.802l54.691-29.226-2.093-.603Z" />
    <path fill="#3F92DC" d="m60.996 3.06 54.934 29.2-30.777-2.432L30.187.143l30.81 2.918Z" />
    <path fill="#4888C1" d="M85.774 29.77 55.522 64.774 0 35.775 30.469 0l55.305 29.77Z" />
    <path fill="#0F83CE" d="m115.997 32.23-30.406-2.46-30.068 34.888 3.047-1.714L86.359 31.2l25.761 2.06 3.877-1.03Z" />
    <path fill="#EAEBDC" d="M62.297 35.66v-7.434l6.555 1.316v9.549l-6.555-3.43Z" />
    <path fill="#BEB19F" d="m68.848 39.09 5.57-6.573V28.57l-5.57.972v9.549Z" />
    <path fill="#F2F2F2" d="M74.422 28.57 67.9 25.02l-5.602 3.205 6.555 3.317 5.57-2.973Z" />
    <path fill="#4D4D4D" d="m64.695 28.17 3.387-1.776 3.847 2.148-3.14 1.77-4.094-2.143Z" />
    <path fill="#333" d="M68.082 26.394v3.549l-3.387-1.774 3.387-1.775Z" />
    <path fill="#B1B4C1" d="M24.069 55.62v18.59L9.05 66.204V47.614l15.018 8.006Z" />
    <path fill="url(#a)" d="M22.663 71.752V56.596l-12.249-6.522V65.23l12.25 6.522Z" />
    <path fill="#fff" d="m11.768 64.489-.061-13.643-1.293-.772V65.23l12.25 6.522V70.32L11.767 64.49Z" />
    <path fill="#B1B4C1" d="M47.596 68.375v18.618l-15.018-8.037v-18.53l15.018 7.949Z" />
    <path fill="url(#b)" d="M46.177 84.562V69.406L33.93 62.858v15.127l12.247 6.577Z" />
    <path fill="#fff" d="m35.284 77.271-.032-13.643-1.322-.77v15.127l12.247 6.577v-1.487l-10.893-5.804Z" />
    <path fill="#9497A4" d="M66.695 65.972v18.246l14.741-7.864V58.111l-14.74 7.861Z" />
    <path fill="url(#c)" d="M67.953 81.99V66.832l12.218-6.492v15.128l-12.218 6.52Z" />
    <path
      fill="#E2DCDC"
      d="M78.878 74.753v-13.64l1.293-.772v15.128l-12.218 6.52v-1.43l10.925-5.806ZM90.62 78.301l13.359-7.18V41.895l-.37-.23-13.355 7.495v29.054l.366.087Z"
    />
    <path fill="#7D808D" d="M103.976 71.121V41.837L90.617 49.33v28.942l13.359-7.15Z" />
    <path fill="#9497A4" d="M102.899 70.492V43.468l-11.262 6.205v26.882l11.262-6.063Z" />
    <path
      fill="#E2DCDC"
      d="M99.062 48.014c.525.343.892.974.892 1.831v.086l-5.478 3.004c0 .03-.058.057-.058.112l.058.344 6.125-3.288v-.086c0-1.201-.647-1.86-1.539-2.003Z"
    />
    <path
      fill="url(#d)"
      d="M99.95 49.845c0-.857-.366-1.488-.89-1.831-.491-.057-.953-.03-1.54.286-1.662.803-2.986 2.86-3.047 4.635l5.478-3.004v-.086Z"
    />
    <path fill="#E2DCDC" d="m99.896 69.863-5.478 3.003v.771l6.124-3.373V51.047l-.646.4v18.416Z" />
    <path fill="url(#e)" d="m99.896 51.447-5.478 2.973v18.446l5.478-3.003V51.447Z" />
    <path fill="#999" d="M102.385 58.596c0 .287-.248.545-.555.574a.524.524 0 0 1-.521-.515.52.52 0 0 1 .521-.513c.309-.029.555.198.555.456v-.002Z" />
    <path fill="#C0C6C1" d="M105.984 74.153 92.875 81.36v2.232l13.109-7.207v-2.23Z" />
    <path fill="#A4A7B4" d="m90.504 80.072 2.372 1.287v2.232l-2.372-1.258v-2.261Z" />
    <path fill="#DDDDCA" d="m105.986 74.153-13.11 7.206-2.372-1.287 13.236-7.148 2.246 1.229Z" />
    <path fill="#CAC4C4" d="m108.381 75.41-13.139 7.178v2.261l13.139-7.207V75.41Z" />
    <path fill="#A4A7B4" d="m92.875 81.3 2.37 1.288v2.261l-2.37-1.258v-2.29Z" />
    <path fill="#F7F1F1" d="m108.383 75.41-13.139 7.178-2.369-1.287 13.233-7.148 2.275 1.258Z" />
    <path fill="#CAC4C4" d="m105.984 72.265-13.109 7.209v2l13.109-7.206v-2.003Z" />
    <path fill="#A4A7B4" d="m90.504 78.186 2.372 1.288v2l-2.372-1.287v-2Z" />
    <path fill="#F7F1F1" d="m105.986 72.265-13.11 7.209-2.372-1.288 13.236-7.18 2.246 1.259Z" />
    <defs>
      <linearGradient id="a" x1={16.538} x2={16.538} y1={45.717} y2={82.798} gradientUnits="userSpaceOnUse">
        <stop stopColor="#74A5C5" />
        <stop offset={1} stopColor="#6C93AF" />
      </linearGradient>
      <linearGradient id="b" x1={40.053} x2={40.053} y1={58.493} y2={95.625} gradientUnits="userSpaceOnUse">
        <stop stopColor="#74A5C5" />
        <stop offset={1} stopColor="#6C93AF" />
      </linearGradient>
      <linearGradient id="c" x1={74.062} x2={74.062} y1={55.99} y2={93.022} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4F748F" />
        <stop offset={1} stopColor="#415E73" />
      </linearGradient>
      <linearGradient id="d" x1={97.212} x2={97.212} y1={32.092} y2={101.134} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4F748F" />
        <stop offset={1} stopColor="#415E73" />
      </linearGradient>
      <linearGradient id="e" x1={97.157} x2={97.157} y1={32.184} y2={100.834} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4F748F" />
        <stop offset={1} stopColor="#415E73" />
      </linearGradient>
    </defs>
  </svg>
);
export default Edificaciones;
