import { SVGProps } from "react";
const Muebles = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={91} height={106} fill="none" {...props}>
    <path
      fill="#0F83CE"
      d="M58.587 47.704 88.33 65.597c-1.801-1.084-4.8-1.159-8.783-.164L49.805 47.54c3.983-.995 6.981-.92 8.782.164ZM53.813 36.338a37.418 37.418 0 0 0 2.023-5.063c9.913 5.966 19.827 11.93 29.741 17.894a37.398 37.398 0 0 1-2.023 5.062L53.812 36.338Z"
    />
    <path
      fill="#0F83CE"
      d="M72.996 66.386 43.254 48.493c.813-.514.893-.568.973-.622 4.013-2.735 7.362-7.07 9.587-11.534L83.556 54.23c-2.226 4.464-5.575 8.8-9.587 11.534-.08.054-.16.108-.973.622Z"
    />
    <path fill="#0E5F94" d="M55.84 31.275c.822-2.625 1.25-5.17 1.243-7.573l29.742 17.893c.007 2.404-.422 4.95-1.244 7.575L55.84 31.274Z" />
    <path fill="#0E5F94" d="M86.819 41.595 57.077 23.702l-.026-9.763 29.741 17.893.027 9.763Z" />
    <path
      fill="#0F83CE"
      d="M86.792 31.832 57.051 13.939l3.741-2.237 29.742 17.894-3.742 2.236ZM45.19 82.643 15.45 64.75 43.25 48.493l29.742 17.893L45.19 82.643ZM30.32 105.216.578 87.323C.567 83.235 5.16 76.838 12.336 70.171l29.742 17.893c-7.177 6.667-11.77 13.064-11.758 17.152ZM38.89 82.624 9.148 64.731c2.354.772 4.128.72 6.054.101l.247-.082L45.19 82.643c-2.06.71-3.886.772-6.3-.019Z"
    />
    <path fill="#0F83CE" d="M8.477 64.376c.219.131.445.25.677.355l29.742 17.893c-.536-.243 1.774 1.12-30.42-18.248Z" />
    <path
      fill="#168DB1"
      d="M8.54 64.334c-1.367-.822-2.23-1.828-2.868-3.116l29.741 17.893c.638 1.287 1.5 2.293 2.869 3.116L8.54 64.334ZM35.41 79.1 5.668 61.207l50.214-30.013 29.742 17.893L35.41 79.1Z"
    />
    <path fill="#168DB1" d="m5.673 61.218-.005-.011L35.41 79.1c-7.435-4.47-19.823-11.918-29.737-17.882Z" />
    <path
      fill="#2CB9E4"
      d="M85.62 49.087c-1.988 6.483-6.417 13.225-11.891 16.838-33.998 20.35-23.742 14.233-25.764 15.422-.186.108-.225.13-.263.151-2.153 1.026-2.083 1.01-2.744 1.224-1.749.562-3.62.737-5.366.172-2.17-.936-3.365-2.13-4.186-3.794l50.215-30.013ZM54.395 26.038a.072.072 0 0 1 .079 0c.248-.15.662-.15.912 0a.07.07 0 0 1 .077 0 1.116 1.116 0 0 0-1.069 0ZM3.385 60.643c-.141-.092-.29-.215-.41-.605-.097-.312-.152-.75-.15-1.204-.001.17.107.34.322.468-.002.718.1 1.258.238 1.341Z"
    />
    <path
      fill="#2CB9E4"
      d="m2.824 58.834.001-.383c0 .17.108.34.323.469v.383c-.217-.13-.324-.3-.324-.47ZM3.395 56.648c52.966-31.771 50.964-30.68 51.083-30.61L3.474 56.649a.072.072 0 0 0-.08 0Z"
    />
    <path
      fill="#2CB9E4"
      d="M2.824 58.45c.007-.488.057-.884.149-1.175.117-.376.242-.514.416-.627a.072.072 0 0 1 .08 0c-.25.15-.249.398.002.548-.176.106-.321.878-.324 1.724-.216-.13-.323-.3-.323-.47Z"
    />
    <path fill="#0C6985" d="M85.555 45.88c-.022 1.471-.432 1.72-.567 1.803.14-.084.245-.624.247-1.34.212-.128.319-.295.32-.463Z" />
    <path fill="#0C6985" d="m85.555 45.497-.001.383c0 .167-.108.335-.32.463l.001-.383c.213-.128.32-.295.32-.463Z" />
    <path fill="#2CB9E4" d="m55.464 26.038 29.53 17.65a.07.07 0 0 0-.077 0l-29.53-17.65a.07.07 0 0 1 .077 0Z" />
    <path
      fill="#0C6985"
      d="M84.992 43.688c.181.118.301.251.415.618.035.112.148.528.147 1.19-.001.168-.108.336-.32.464.003-.846-.14-1.618-.317-1.724.25-.15.25-.396-.003-.547a.07.07 0 0 1 .078-.001ZM84.985 47.683 33.98 78.293c.14-.084.245-.624.248-1.34l51.004-30.61c-.002.716-.107 1.255-.247 1.34Z"
    />
    <path fill="#2CB9E4" d="M32.92 78.293 3.39 60.643c-.138-.083-.24-.623-.238-1.34l29.53 17.65c-.002.717.1 1.257.238 1.34Z" />
    <path fill="#0C6985" d="m85.244 45.96-.001.382-51.005 30.61.001-.382 51.005-30.61Z" />
    <path fill="#2CB9E4" d="M32.683 76.57v.382l-29.53-17.65v-.383l29.53 17.65Z" />
    <path
      fill="#0C6985"
      d="M33.983 78.293c-.074.04-.246.136-.534.136-.183 0-.376-.04-.535-.136-.139-.083-.24-.623-.238-1.34.431.258 1.127.256 1.553 0-.002.716-.107 1.255-.247 1.34Z"
    />
    <path
      fill="#0C6985"
      d="M34.23 76.57v.383c-.427.256-1.123.258-1.554 0v-.383c.432.258 1.128.256 1.554 0ZM84.919 44.236c.177.106.32.878.317 1.724L34.232 76.57c.002-.846-.14-1.618-.318-1.724l51.004-30.61Z"
    />
    <path fill="#2CB9E4" d="M33.006 74.846c-.176.106-.321.878-.324 1.724L3.152 58.92c.003-.846.148-1.618.324-1.724l29.53 17.65Z" />
    <path
      fill="#168DB1"
      d="M84.918 43.689c.253.15.252.397.002.546-7.518 4.523-43.486 26.087-51.004 30.61a.975.975 0 0 1-.912 0l-29.53-17.65c-.25-.149-.252-.397-.003-.546 7.518-4.523 43.486-26.087 51.005-30.61.248-.15.662-.15.912 0l29.53 17.65Z"
    />
    <path
      fill="#0C6985"
      d="M33.912 74.846c.177.106.32.878.317 1.724-.426.256-1.122.258-1.553 0 .002-.846.147-1.618.324-1.724.253.151.663.15.912 0Z"
    />
    <path fill="#168DB1" d="m27.615 9.24-3.818-1.41C31.998 2.929 40.449.324 49.15 0l3.818 1.41c-8.702.322-17.152 2.928-25.354 7.83Z" />
    <path fill="#0C6985" d="m7.623 56.183-3.818-1.41L0 29.38l3.818 1.41 3.805 25.393Z" />
    <path fill="#168DB1" d="M3.818 30.79 0 29.38c7.68-9.476 15.616-16.656 23.801-21.55l3.819 1.41c-8.185 4.893-16.122 12.073-23.802 21.55Z" />
    <path
      fill="#2CB9E4"
      d="M27.617 9.24C35.82 4.338 44.27 1.732 52.971 1.41l3.805 25.393-49.155 29.38L3.816 30.79c7.68-9.476 15.617-16.656 23.802-21.55Z"
    />
    <path fill="#0F83CE" d="M30.394 65.54.652 47.649l3.742-2.237 29.742 17.893-3.742 2.237Z" />
    <path
      fill="#0E5F94"
      d="M35.561 86.987 5.821 69.095C2.658 67.192.695 63.15.68 57.41l-.027-9.763 29.74 17.892.03 9.764c.015 5.74 1.977 9.78 5.14 11.683Z"
    />
    <path
      fill="#0766A4"
      d="m90.538 29.596.027 9.763c.024 8.806-4.547 19.104-11.022 26.074 6.796-1.697 10.723-.282 10.735 3.947L30.32 105.217c-.01-4.089 4.581-10.485 11.758-17.153-6.767 1.112-11.627-3.718-11.652-12.76l-.027-9.763 3.742-2.236.026 9.763c.014 4.916 1.843 8.25 4.727 9.557 2.413.79 4.24.728 6.3.018 6.596-3.867 27.792-16.21 28.776-16.879 4.013-2.734 7.361-7.07 9.587-11.533 2.13-4.413 3.277-8.712 3.266-12.636l-.026-9.763 3.741-2.236Z"
    />
    <path
      fill="#4F575F"
      d="M33.876 46.063c-.338 1.192-.566 1.594-1.838 2.47-.668.483-1.268.409-2.08.483l-5.342-3.056c-1.166-4.493-4.631-11.858-4.8-15.787l1.814.569c2.907 3.93.117 1.678 3.33 1.173 7.355-1.279 12.995.506 8.916 14.148Z"
      opacity={0.4}
    />
    <path
      fill="#EBEEF4"
      d="M51.985 34.546a.842.842 0 0 1-.004 1.1C43.449 46.18 33.395 48.652 30.598 49.158c-.427.073-.798-.216-.897-.64-1.45-6.705-7.685-15.32-9.566-18.149a.82.82 0 0 1 .447-1.244c5.896-1.849 12.408-5.024 20.326-11a.78.78 0 0 1 1.226.407c1.8 5.525 5.781 11.021 8.239 14.088.682.84 1.236 1.498 1.612 1.926Z"
    />
    <path
      fill="#DBDFE6"
      d="M51.985 34.546a.842.842 0 0 1-.004 1.1C43.449 46.18 33.395 48.652 30.598 49.158c-.427.073-.798-.216-.897-.64-1.45-6.705-7.685-15.32-9.566-18.149a.82.82 0 0 1 .447-1.244 51.423 51.423 0 0 0 3.689-1.307c.55 1.317.268 2.538.612 3.914.326 1.22.74 1.718 4.475 7.455.566.874 1.164 1.768 2.055 2.27 1.333.744 3.035.376 4.32-.455 2.568-1.66 4.084-4.99 7.187-5.6.883-.163 1.814-.053 2.712-.126 1.835-.149 3.583-1.156 4.74-2.656.683.84 1.237 1.498 1.613 1.925Z"
    />
  </svg>
);
export default Muebles;
