import { SVGProps } from "react";
const Terrenos = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={59} height={79} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M25.002 78.91c.965.242 9.125-3.51 9.125-3.51s21.715-21.035 24.346-39.305C61.106 17.805 42.156-3.12 25.926.388c-8.115 2.895-10.442 3.948-10.442 3.948s17.679-5.79 30.576 18.206c14.234 27.832-17.437 41.74-21.058 56.367Z"
      />
      <path
        fill="#05A7D8"
        d="M22.657 8.153C7.15 26.491 25.925 50.88 41.496 49.126c1.843 1.402-22.767 11.184-28.535 4.891-5.768-6.296-8.993-19.894-8.993-30.707 0-10.814 17.569-16.582 18.688-15.157Z"
      />
      <path
        fill="url(#c)"
        d="M50.14 37.544c0 15.968-23.776 41.696-25.093 41.455C23.71 78.734 0 44.125 0 28.2S11.231 1.398 25.091 3.986c13.819 2.588 25.05 17.635 25.05 33.557ZM25.047 53.116c10.53 1.974 19.06-6.272 19.06-18.38.023-12.128-8.51-23.557-19.06-25.509C14.564 7.275 6.01 15.48 6.01 27.61c0 12.13 8.51 23.532 19.038 25.507h-.001Z"
      />
    </g>
    <defs>
      <linearGradient id="b" x1={19.024} x2={68.325} y1={34.877} y2={56.548} gradientUnits="userSpaceOnUse">
        <stop stopColor="#00BFF8" />
        <stop offset={1} stopColor="#0069B2" />
      </linearGradient>
      <linearGradient id="c" x1={8.883} x2={47.335} y1={-11.244} y2={91.654} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={0.5} stopColor="#49DEFF" />
        <stop offset={1} stopColor="#0588C1" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h58.72v79H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Terrenos;
