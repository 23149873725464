import React from "react";
import ConfigRoutes from "routes";
import ThemeConfig from "theme";
import GlobalStyles from "theme/GlobalStyles";
import { BrowserRouter } from "react-router-dom";
import NapContableProvider from "context";
import { HelmetProvider } from "react-helmet-async";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import esLocale from "date-fns/locale/es";
import { ConfigProvider } from "antd";
import moment from "moment-timezone";
import es from "antd/lib/locale/es_ES";
import WebSocketProvider from "context/webSocketContext";

moment.tz.setDefault("America/Lima");

const App: React.FC = (): JSX.Element => (
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <ConfigProvider locale={es}>
        <ThemeConfig>
          <GlobalStyles />
          <BrowserRouter>
            <NapContableProvider>
              <WebSocketProvider>
                <ConfigRoutes />
              </WebSocketProvider>
            </NapContableProvider>
          </BrowserRouter>
        </ThemeConfig>
      </ConfigProvider>
    </LocalizationProvider>
  </HelmetProvider>
);

export default App;
