import { SVGProps } from "react";
const Vehiculos = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={159} height={69} fill="none" {...props}>
    <path
      fill="#C1EFFD"
      d="M.808 65.32c0 2.029 1.6 3.68 3.568 3.68H153.62c1.967 0 3.569-1.651 3.569-3.68 0-2.028-1.602-3.68-3.569-3.68H4.376c-1.967 0-3.568 1.652-3.568 3.68Z"
    />
    <path
      fill="#0A2649"
      d="M98.556 1.44c5.8 2.679 15.559 8.8 22.143 21.577.587 1.133.17 2.538-.937 3.145a2.18 2.18 0 0 1-1.15.272 2.228 2.228 0 0 1-1.891-1.23C108.035 8.343 92.858 4.198 92.654 4.145c-1.167-.299-1.882-1.493-1.66-2.696h7.562V1.44Z"
    />
    <path fill="#0792BC" d="M22.76 21.542h113.192c11.727 0 21.232 9.8 21.232 21.894H1.528c0-12.093 9.504-21.894 21.231-21.894Z" />
    <path
      fill="#0792BC"
      d="M25.255 2.459c6.864.641 13.643 1.282 20.482.606.307 2.635.596 5.278.818 7.921-.18 4.18-.239 8.352-.034 12.532H16.849c0-8.211 3.21-15.64 8.406-21.05v-.009Z"
    />
    <path fill="#0086CF" d="M16.907 21.542h8.738v.694h-8.738v-.694Z" />
    <path fill="#0A2649" d="M117.758 23.51H25.414V2.467h65.031l12.153 6.621 15.16 14.42Z" />
    <path
      fill="#0A346C"
      d="M98.848 23.51H78.39L57.986 2.467h20.456l20.406 21.041ZM39.762 2.468h10.399l20.405 21.041H60.168L39.762 2.468ZM30.283 2.468h3.108l20.406 21.041h-3.109L30.283 2.468Z"
    />
    <path
      fill="#0792BC"
      d="M115.825 25.837c.392 0 .784-.106 1.142-.325 1.073-.65 1.43-2.073.8-3.18C107.292 3.997 90.54.335 89.833.194c-1.226-.254-2.41.562-2.649 1.818-.247 1.256.546 2.477 1.763 2.731.205.044 15.543 3.496 24.945 19.944a2.231 2.231 0 0 0 1.942 1.142l-.009.009Z"
    />
    <path
      fill="#0792BC"
      d="M21.809 2.468c0 1.36 1.073 2.467 2.393 2.467h65.057c1.32 0 2.393-1.106 2.393-2.467S90.58 0 89.26 0H24.202c-1.32 0-2.393 1.107-2.393 2.468Z"
    />
    <path
      fill="#0792BC"
      d="M65.442 2.459h2.69v24.194h-2.69V2.46ZM157.184 42.171v6.938H1.528V42.17l11.812-3.767 18.345-2.582s81.085.395 81.664 0c.579-.396 36.928 2.775 36.928 2.775"
    />
    <path fill="#0086CF" d="M66.788 23.738h.673v27.9h-.673v-27.9ZM25.073 23.738h.673v27.9h-.673v-27.9ZM113.288 23.738h.673v27.9h-.673v-27.9Z" />
    <path
      fill="#0086CF"
      d="m146.496 24.432-1.337-.694H113.29v.694h33.206ZM-.006 53.148c0 1.757 1.38 3.18 3.083 3.417h152.785c1.704-.246 3.083-1.669 3.083-3.416v-2.837c0-1.756-1.379-3.18-3.083-3.18H3.077c-1.704 0-3.083 1.424-3.083 3.18v2.837Z"
    />
    <path
      fill="#000D21"
      d="M111.911 52.296c0-9.51 7.477-17.221 16.701-17.221 9.223 0 16.7 7.71 16.7 17.221 0 1.388-.178 2.732-.476 4.031h-32.448a17.868 17.868 0 0 1-.477-4.03Z"
    />
    <path
      fill="#0A365F"
      d="M142.152 52.297c0 7.719-6.063 13.972-13.549 13.972s-13.55-6.253-13.55-13.972c0-7.72 6.064-13.972 13.55-13.972 7.486 0 13.549 6.252 13.549 13.972Z"
    />
    <path
      fill="#DBDCD7"
      d="M138.648 52.297c0 5.717-4.496 10.354-10.04 10.354-5.545 0-10.041-4.637-10.041-10.354s4.496-10.354 10.041-10.354c5.544 0 10.04 4.637 10.04 10.354Z"
    />
    <path
      fill="#0A2649"
      d="M137.105 52.296c0 4.84-3.806 8.765-8.499 8.765-4.693 0-8.499-3.926-8.499-8.765 0-4.838 3.806-8.764 8.499-8.764 4.693 0 8.499 3.926 8.499 8.764Z"
    />
    <path
      fill="#DBDCD7"
      d="M132.746 52.297c0 2.362-1.856 4.268-4.139 4.268-2.282 0-4.139-1.915-4.139-4.268 0-2.354 1.857-4.268 4.139-4.268 2.283 0 4.139 1.914 4.139 4.268Z"
    />
    <path
      fill="#0A2649"
      d="M130.367 52.297c0 1-.783 1.809-1.754 1.809s-1.755-.808-1.755-1.81c0-1 .784-1.809 1.755-1.809.971 0 1.754.808 1.754 1.81Z"
    />
    <path
      fill="#DBDCD7"
      d="M127.953 43.058h1.328v6.85h-1.328v-6.85ZM127.953 54.72h1.328v6.85h-1.328v-6.85ZM130.939 51.63h6.643v1.36h-6.643v-1.36ZM119.627 51.63h6.643v1.36h-6.643v-1.36ZM130.716 53.53l4.698 4.843-.939.97-4.698-4.843.939-.97ZM122.725 45.296l4.697 4.844-.94.969-4.696-4.845.939-.968ZM126.482 53.523l.94.969-4.697 4.843-.94-.968 4.697-4.844ZM134.475 45.291l.939.97-4.698 4.842-.939-.969 4.698-4.843Z"
    />
    <path
      fill="#000D21"
      d="M11.278 52.297c0-9.511 7.478-17.222 16.7-17.222 9.224 0 16.702 7.71 16.702 17.222 0 1.387-.18 2.73-.477 4.03H11.755a17.861 17.861 0 0 1-.477-4.03Z"
    />
    <path
      fill="#0A365F"
      d="M41.52 52.297c0 7.719-6.064 13.972-13.55 13.972-7.486 0-13.55-6.253-13.55-13.972 0-7.72 6.064-13.972 13.55-13.972 7.486 0 13.55 6.252 13.55 13.972Z"
    />
    <path
      fill="#DBDCD7"
      d="M38.016 52.297c0 5.717-4.497 10.354-10.041 10.354-5.545 0-10.041-4.637-10.041-10.354s4.496-10.354 10.04-10.354c5.545 0 10.042 4.637 10.042 10.354Z"
    />
    <path
      fill="#0A2649"
      d="M36.477 52.297c0 4.839-3.807 8.764-8.5 8.764-4.692 0-8.5-3.925-8.5-8.764 0-4.84 3.808-8.765 8.5-8.765 4.693 0 8.5 3.926 8.5 8.765Z"
    />
    <path
      fill="#DBDCD7"
      d="M32.113 52.297c0 2.362-1.856 4.268-4.139 4.268-2.282 0-4.139-1.915-4.139-4.268 0-2.354 1.857-4.268 4.14-4.268 2.282 0 4.138 1.914 4.138 4.268Z"
    />
    <path
      fill="#0A2649"
      d="M29.734 52.297c0 1-.783 1.809-1.754 1.809-.97 0-1.754-.808-1.754-1.81 0-1 .783-1.809 1.754-1.809.97 0 1.754.808 1.754 1.81Z"
    />
    <path
      fill="#DBDCD7"
      d="M27.325 43.058h1.32v6.85h-1.32v-6.85ZM27.325 54.72h1.32v6.85h-1.32v-6.85ZM30.306 51.63h6.643v1.36h-6.643v-1.36ZM18.994 51.63h6.643v1.36h-6.643v-1.36ZM30.076 53.53l4.697 4.843-.939.969-4.697-4.844.94-.968ZM22.085 45.302l4.696 4.844-.94.969-4.696-4.845.94-.968ZM25.854 53.524l.939.969-4.697 4.843-.94-.968 4.698-4.844ZM33.854 45.28l.939.97-4.7 4.84-.939-.969 4.7-4.841Z"
    />
    <path
      fill="#fff"
      d="M151.269 43.313c.085 0 .17 0 .256-.01 1.788-.14 3.125-1.755 2.989-3.6-.75-9.994-8.074-12.69-8.38-12.795-1.695-.597-3.543.334-4.131 2.09-.579 1.73.307 3.618 1.976 4.242.409.167 3.654 1.642 4.054 6.99.128 1.757 1.558 3.092 3.236 3.092v-.01Z"
    />
    <path
      fill="#0086CF"
      d="M149.034 45.262c0 .562.442 1.019.988 1.019h3.193c.545 0 .988-.457.988-1.019 0-.562-.443-1.019-.988-1.019h-3.193c-.546 0-.988.457-.988 1.02ZM70.791 27.98c0 .693.545 1.255 1.218 1.255h3.918c.672 0 1.217-.562 1.217-1.256s-.544-1.256-1.217-1.256h-3.918c-.673 0-1.218.562-1.218 1.256Z"
    />
    <path
      fill="#fff"
      d="M124.297 27.68c0 .695.545 1.257 1.218 1.257h11.931c.673 0 1.218-.562 1.218-1.256s-.545-1.256-1.218-1.256h-11.931c-.673 0-1.218.562-1.218 1.256ZM84.061 27.68c0 .695.545 1.257 1.21 1.257h23.292c.673 0 1.218-.562 1.218-1.256s-.545-1.256-1.218-1.256H85.271c-.673 0-1.21.562-1.21 1.256ZM38.858 27.68c0 .695.545 1.257 1.218 1.257h23.292c.673 0 1.218-.562 1.218-1.256s-.545-1.256-1.218-1.256H40.075c-.672 0-1.217.562-1.217 1.256ZM117.16 27.68c0 .695.545 1.257 1.218 1.257h2.666c.673 0 1.218-.562 1.218-1.256s-.545-1.256-1.218-1.256h-2.666c-.673 0-1.218.562-1.218 1.256ZM148.467 50.487c0 .694.545 1.256 1.218 1.256h5.859c.673 0 1.218-.562 1.218-1.256 0-.693-.545-1.255-1.218-1.255h-5.859c-.673 0-1.218.562-1.218 1.255ZM92.468 50.487c0 .694.545 1.256 1.218 1.256h15.202c.672 0 1.217-.562 1.217-1.256 0-.693-.545-1.255-1.217-1.255H93.686c-.673 0-1.218.562-1.218 1.255ZM83.448 50.487c0 .694.545 1.256 1.218 1.256h4.241c.673 0 1.218-.562 1.218-1.256 0-.693-.545-1.255-1.218-1.255h-4.241c-.673 0-1.218.562-1.218 1.255ZM3.054 50.487c0 .694.545 1.256 1.217 1.256h4.242c.672 0 1.217-.562 1.217-1.256 0-.693-.545-1.255-1.217-1.255H4.27c-.672 0-1.217.562-1.217 1.255ZM16.457 27.68c0 .695.545 1.257 1.218 1.257h2.666c.672 0 1.218-.562 1.218-1.256s-.546-1.256-1.218-1.256h-2.666c-.673 0-1.218.562-1.218 1.256ZM84.056 2.459c0 .694.545 1.256 1.209 1.256h2.666c.672 0 1.217-.562 1.217-1.256s-.545-1.256-1.217-1.256h-2.666c-.673 0-1.21.562-1.21 1.256Z"
      opacity={0.2}
    />
    <path
      fill="#C1EFFD"
      d="M153.188 38.825c0 1.186-.929 2.143-2.079 2.143-1.149 0-2.078-.957-2.078-2.143 0-1.185.929-2.143 2.078-2.143 1.15 0 2.079.958 2.079 2.143ZM150.094 34.021c0 .58-.46 1.045-1.014 1.045-.553 0-1.013-.465-1.013-1.045 0-.58.451-1.045 1.013-1.045.562 0 1.014.466 1.014 1.045ZM148.051 31.44c0 .58-.452 1.045-1.014 1.045s-1.013-.466-1.013-1.045c0-.58.451-1.046 1.013-1.046.562 0 1.014.466 1.014 1.046Z"
    />
    <path fill="#000D21" d="M107.864 21.63c.698 0 1.269.588 1.269 1.309v1.59c0 .72-.571 1.308-1.269 1.308V21.62v.01Z" />
    <path fill="#0086CF" d="M104.183 17.608c2.035 0 3.696 1.704 3.696 3.811v4.628c0 2.1-1.652 3.812-3.696 3.812V17.607Z" />
  </svg>
);
export default Vehiculos;
