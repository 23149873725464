import axios from "axios";
import { API_CONTABLE, API_CUENTAS, API_RECLAMOS, API_WORKERS } from "config/api.config";
import UserService from "config/UserService";

export const consultSunat = async (tdoc: "ruc" | "dni", ndoc: string) => {
  const res = await axios.post(`${API_RECLAMOS}/consult/${tdoc}`, { [tdoc]: ndoc }, { headers: { Authorization: UserService.token() } });
  return res.data;
};

export const getCustomerForVoucher = async (type: "ruc" | "dni", ruc: string) => {
  const res = await axios.post(`${API_CONTABLE}/ruc`, { ruc, type }, { headers: { Authorization: UserService.token() } });
  return res.data;
};

export const getCharts = async (year: number, headquarterCode: string, companyUrl: string) => {
  const res = await axios.get(`${API_CUENTAS}/charts/${year}/${headquarterCode}/${companyUrl}`);
  return res.data;
};

export const insertQRIntoPdfFile = async (body: { QR_BASE64: string; PDF_URL: string }): Promise<string> => {
  const res = await axios.post(`${API_WORKERS}/files/pdf-with-qr`, body);
  return res.data?.base64;
};
