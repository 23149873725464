import { AuthAxios } from "./config";

export const authUser = async (url: string, data: object) => {
  const res = await AuthAxios.post(`/login/${url}`, data);
  return res.data;
};

export const validTokenStatus = async (userId: string, headquarterId: string) => {
  if (!userId || !headquarterId) return;

  const res = await AuthAxios.get(`/validToken/${userId}/${headquarterId}`);
  return res.data;
};

export const validToken = async () => {
  const res = await AuthAxios.post(`/validToken`);
  return res.data;
};

export const getUsers = async (sedeId: string) => {
  const res = await AuthAxios.get(`/users/${sedeId}`);
  return res.data;
};

export const getDisabledUsers = async (sedeId: string) => {
  const res = await AuthAxios.get(`/users/disabled/${sedeId}`);
  return res.data;
};

export const getAllUsers = async () => {
  const res = await AuthAxios.get(`/users`);
  return res.data;
};

export const createRoom = async (userId: string, user: string) => {
  const res = await AuthAxios.post(`/user/create-room/${userId}`, { user });
  return res.data;
};

export const postUsers = async (data: object) => {
  const res = await AuthAxios.post(`/create-user`, data);
  return res.data;
};

export const putUsers = async (data: object, userId: string) => {
  const res = await AuthAxios.put(`/edit-user-info/${userId}`, data);
  return res.data;
};

// ASIGN MODULES

export const getModules = async (endpoint: string) => {
  const res = await AuthAxios.get(`${endpoint}`);
  return res.data;
};

export const assignModules = async (endpoint: string, data: object) => {
  const res = await AuthAxios.put(`${endpoint}`, data);
  return res.data;
};

export const resendPassword = async (userId: string) => {
  const res = await AuthAxios.post(`/auth/recovery-password/${userId}`, {});
  return res.data;
};

export const getHeadquartersByUser = async (userId: string, companyId: string) => {
  const res = await AuthAxios.get(`/my-headquarters/${userId}/${companyId}`);
  return res.data;
};

export const updateImageProfile = async (companyUrl: string, data: object) => {
  const res = await AuthAxios.post(`/upload-img-profile/${companyUrl}`, data);
  return res.data;
};

export const updateUserPassword = async (data: object) => {
  const res = await AuthAxios.post(`/auth/change_password`, data);
  return res.data;
};

export const requestForgotPassword = async (data: object) => {
  const res = await AuthAxios.post(`/auth/forgot_password`, data);
  return res.data;
};

export const confirmTokenResetPassword = async (data: object) => {
  const res = await AuthAxios.post(`/auth/reset_password`, data);
  return res.data;
};
