/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useNapContableContext } from "context";
import { ContentProps, MessageProps, WebSocketProviderProps, WSSContextProps } from "interfaces/messages";
import { io } from "socket.io-client";
import { API_MESSAGES } from "config/api.config";
import { HeadquarterProps } from "interfaces/headquarter";
import { getMessages } from "requests/messages";
import moment from "moment-timezone";
import { UserProps } from "interfaces/user";
import { createRoom } from "requests/user";

const socket = io(String(API_MESSAGES));

const WebSocket = createContext({} as WSSContextProps);

export const useWebSocketContext = () => useContext(WebSocket);

const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const { user, company, year } = useNapContableContext();
  const bottomRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<Array<MessageProps>>([]);
  const [selectedHeadquarter, setSelectedHeadquarter] = useState<null | HeadquarterProps>(null);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [headquarterSocket, setHeadquarterSocket] = useState("");
  const [userSocket, setUserSocket] = useState<UserProps | null>(null);
  const [room, setRoom] = useState({ actual: null, last: null });

  useEffect(() => {
    if (messages.length) bottomRef.current?.scrollIntoView({ behavior: "auto", block: "start" });
  }, [messages]);

  useEffect(() => {
    if (headquarterSocket && user && userSocket) handleGetMessages();
  }, [headquarterSocket, userSocket]);

  const handleGetMessages = async () => {
    try {
      const uRoom = await createRoom(user._id!, userSocket!._id!);
      socket.emit("JOIN_HEADQUARTER", { room: uRoom, leave: room?.actual ?? null });
      setLoading(true);
      setRoom({ actual: uRoom, last: room?.actual ?? null });
      setMessages([]);
      const params = { room: uRoom };
      const res = await getMessages(params);
      setMessages([...res]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async (content: ContentProps) => {
    try {
      setLoading(true);
      const params: MessageProps = {
        company: company._id!,
        headquarter: headquarterSocket,
        year,
        month: moment().month(),
        content,
        datetime: moment().toISOString(),
        room: room.actual!,
        sender: {
          email: user.email!,
          fullname: user.fullname!,
          photo: user.profile_picture,
          t_doc: user.t_doc!,
          n_doc: user.n_doc!,
          rol: user.rol!,
          _id: user._id!,
        },
      };

      socket.emit("SEND_MESSAGE", params);
      socket.on("LAST_MESSAGE", (received) => {
        if (received.room === room?.actual) {
          messages.push(received);
          setMessages([...messages]);
        }
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <WebSocket.Provider
      value={{
        selectedHeadquarter,
        setSelectedHeadquarter,
        messages,
        handleSendMessage,
        bottomRef,
        openDetails,
        setOpenDetails,
        loading,
        headquarterSocket,
        setHeadquarterSocket,
        userSocket,
        setUserSocket,
      }}
    >
      {children}
    </WebSocket.Provider>
  );
};
export default WebSocketProvider;
