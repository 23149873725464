export enum SurfacesUrlParams {
  GROUNDS = "grounds",
  EDIFICATIONS = "edifications",
}

export enum SurfacesSingular {
  GROUND = "ground",
  EDIFICATION = "edification",
}

export enum TypeAssetsEnum {
  MACHINERY = "machinery",
  FURNITURE = "furniture",
  VEHICLES = "vehicles",
  EQUIPMENT = "equipment",
  RELIGIOUS = "religious",
  INTANGIBLES = "intangibles",
  CONSUMABLES = "consumables",
  BOOKS = "books",
}

export enum AssetStatusEnum {
  BUENO = "BUENO",
  REGULAR = "REGULAR",
  DETERIORADO = "DETERIORADO",
  "FALTA ARREGLAR" = "FALTA ARREGLAR",
  MALOGRADO = "MALOGRADO",
  DESUSO = "DESUSO",
}

export enum TypeVoucherDocumentsEnum {
  BOLETA = "BOLETA",
  FACTURA = "FACTURA",
  OTROS = "OTROS",
}

export enum GlobalModeModalStateEnum {
  UNDEFINED = "",
  BOX = "box",
  DETAIL = "detail",
  DELETE = "delete",
  RESTORE = "restore",
  FILE = "file",
  TRANSFER = "transfer",
  DISABLE = "disable",
  HISTORY = "history",
  MASSIVE = "massive",
}

export enum InventoryTypeEnum {
  GROUND = "ground",
  EDIFICATION = "edification",
  FIXEDASSET = "fixedasset",
}
