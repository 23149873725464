import axios from "axios";
import { API_MESSAGES } from "config/api.config";
import UserService from "config/UserService";
import { ParamsGetMessageProps } from "interfaces/messages";
import { buildQueryParams } from "utils/functions";

export const getMessages = async (params: ParamsGetMessageProps) => {
  const res = await axios.get(`${API_MESSAGES}/message?${buildQueryParams(params)}`, { headers: { Authorization: UserService.token() } });
  return res.data;
};
