import { Route, Routes } from "react-router-dom";
import routes from "./config";
import { useNapContableContext } from "context";
import Page401 from "pages/errors/Page401";

const ConfigRoutes = () => {
  const { user, loading } = useNapContableContext();
  const filteredRoutes = routes.filter((item) => item.isPrivate === Boolean(user._id));

  return (
    <Routes>
      {!loading &&
        filteredRoutes.map((e, ind) => {
          const { path, element: Component, children, isPrivate } = e;
          return isPrivate ? (
            <Route path={path} element={<Component />} key={ind}>
              {children?.map((el, indx) => {
                const { path: ruta, element: Element, roles } = el;
                let flag = false;
                if (roles.includes(user.rol ?? "")) flag = true;
                return <Route path={ruta} element={flag ? <Element /> : <Page401 />} key={indx} />;
              })}
            </Route>
          ) : (
            <Route path={path} element={<Component />} key={ind}>
              {children?.map((el, indx) => {
                const { path: ruta, element: Element } = el;
                return <Route path={ruta} element={<Element />} key={indx} />;
              })}
            </Route>
          );
        })}
    </Routes>
  );
};

export default ConfigRoutes;
