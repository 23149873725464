import { ContableAxios } from "./config";

export const getCosts = async (year: number, headquarterId: string) => {
  const res = await ContableAxios.get(`/cost/${year}/${headquarterId}`);
  return res.data;
};

export const postCost = async (year: number, headquarterId: string, data: object) => {
  const res = await ContableAxios.post(`/cost/${year}/${headquarterId}`, data);
  return res.data;
};

export const putCost = async (costId: string, data: object) => {
  const res = await ContableAxios.put(`/cost/${costId}`, data);
  return res.data;
};

export const getCostsFormula = async (year: number, headquarterId: string) => {
  const res = await ContableAxios.get(`/cost-formula/${year}/${headquarterId}`);
  return res.data;
};

export const postCostFormula = async (year: number, headquarterId: string, data: object) => {
  const res = await ContableAxios.post(`/cost-formula/${year}/${headquarterId}`, data);
  return res.data;
};

export const putCostFormula = async (costFormulaId: string, data: object) => {
  const res = await ContableAxios.put(`/cost-formula/${costFormulaId}`, data);
  return res.data;
};

export const setDefaultCostFormula = async (costFormulaId: string) => {
  const res = await ContableAxios.put(`/cost-formula/default/${costFormulaId}`, {});
  return res.data;
};
