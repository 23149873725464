import { SVGProps } from "react";
const Enseres = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={70} height={135} fill="none" {...props}>
    <path
      fill="#0086CF"
      d="M41.36 97.981H25.425a15.605 15.605 0 0 0-14.48 9.789H55.84a15.605 15.605 0 0 0-14.48-9.789ZM6.305 127.621h54.181l-2.164-9.704H8.472l-2.167 9.704Z"
    />
    <path
      fill="#4888C1"
      d="M55.846 107.766H10.953a15.58 15.58 0 0 0-.751 2.418l-1.724 7.73h49.849l-1.724-7.73a15.591 15.591 0 0 0-.752-2.418h-.004Z"
    />
    <path
      fill="#4888C1"
      d="M56.59 110.187a15.604 15.604 0 0 0-15.23-12.206H39.2c7.31 0 13.638 5.072 15.23 12.206l3.892 17.434h2.16l-3.892-17.434Z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path fill="#2F2A27" d="M30.227 113.228a3.617 3.617 0 1 0 7.233-.002 3.617 3.617 0 0 0-7.233.002Z" opacity={0.39} />
    <path fill="#2F2A27" d="M33.37 116.404a3.616 3.616 0 1 0 0-7.232 3.616 3.616 0 0 0 0 7.232Z" />
    <path fill="#191614" d="M31.07 112.788c0 1.269 1.032 2.3 2.3 2.3 1.27 0 2.301-1.031 2.301-2.3s-1.031-2.3-2.3-2.3a2.302 2.302 0 0 0-2.3 2.3Z" />
    <path fill="#423C38" d="M31.438 112.788a1.939 1.939 0 1 0 3.877-.001 1.939 1.939 0 0 0-3.877.001Z" />
    <path fill="#191614" d="m32.172 110.908 1.199-2.075 1.203 2.075h-2.402Z" />
    <path fill="#2F2A27" d="M21.187 110.094h-3.449v5.173h3.449v-5.173Z" />
    <path fill="#2F2A27" d="M21.734 110.518h-3.449v5.173h3.449v-5.173Z" opacity={0.39} />
    <path fill="#423C38" d="M20.764 110.729H18.16v3.904h2.604v-3.904Z" />
    <path fill="#191614" d="m17.738 115.267.42-.634h2.605l.42.634h-3.445ZM21.183 110.094l-.42.635h-2.604l-.42-.635h3.444Z" />
    <path fill="#5B5450" d="M18.775 110.729h-.615v3.904h.615v-3.904Z" />
    <path fill="#2F2A27" d="M26.718 110.094H23.27v5.173h3.448v-5.173Z" />
    <path fill="#2F2A27" d="M27.265 110.518h-3.449v5.173h3.449v-5.173Z" opacity={0.39} />
    <path fill="#423C38" d="M26.295 110.729h-2.604v3.904h2.604v-3.904Z" />
    <path fill="#191614" d="m23.27 115.267.42-.634h2.604l.42.634H23.27ZM26.714 110.094l-.42.635H23.69l-.42-.635h3.444Z" />
    <path fill="#5B5450" d="M24.306 110.729h-.615v3.904h.615v-3.904Z" />
    <path fill="#2F2A27" d="M42.968 110.094H39.52v5.173h3.45v-5.173Z" />
    <path fill="#2F2A27" d="M43.515 110.519h-3.449v5.172h3.449v-5.172Z" opacity={0.39} />
    <path fill="#423C38" d="M42.545 110.729h-2.604v3.904h2.604v-3.904Z" />
    <path fill="#191614" d="m39.52 115.267.42-.634h2.604l.42.634H39.52ZM42.964 110.094l-.42.635H39.94l-.42-.635h3.444Z" />
    <path fill="#5B5450" d="M40.556 110.729h-.615v3.904h.615v-3.904Z" />
    <path fill="#2F2A27" d="M48.492 110.094h-3.449v5.173h3.449v-5.173Z" />
    <path fill="#2F2A27" d="M49.046 110.518h-3.448v5.173h3.448v-5.173Z" opacity={0.39} />
    <path fill="#423C38" d="M48.069 110.729h-2.604v3.904h2.604v-3.904Z" />
    <path fill="#191614" d="m45.043 115.267.424-.634h2.604l.42.634h-3.448ZM48.492 110.094l-.42.635h-2.605l-.424-.635h3.449Z" />
    <path fill="#5B5450" d="M46.08 110.729h-.615v3.904h.615v-3.904Z" />
    <path
      fill="#2F2A27"
      d="M33.385 106.658a.421.421 0 1 0-.001-.842.421.421 0 0 0 .001.842ZM36.508 106.973a.42.42 0 1 1-.763-.352.42.42 0 0 1 .764.352ZM30.418 106.973a.42.42 0 1 0 .763-.352.42.42 0 0 0-.764.352ZM38.621 108.728a.419.419 0 1 1-.553-.629.419.419 0 0 1 .553.629ZM28.143 108.728a.419.419 0 1 0 .554-.629.419.419 0 0 0-.554.629ZM59.817 127.621H6.975a3.76 3.76 0 0 0-3.74 3.371h60.323a3.762 3.762 0 0 0-3.74-3.371Z"
    />
    <path fill="#1C1715" d="M3.238 130.992a4.37 4.37 0 0 0-.02.393v2.978h60.363v-2.978c0-.132-.008-.264-.02-.393H3.239Z" />
    <path
      fill="#3D3937"
      d="M59.813 127.621h-2.16a3.76 3.76 0 0 1 3.76 3.76v2.978h2.16v-2.978a3.76 3.76 0 0 0-3.76-3.76Z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#2F2A27"
      d="M13.331 127.621H6.975a3.76 3.76 0 0 0-3.76 3.76v2.978H9.57v-2.978a3.76 3.76 0 0 1 3.76-3.76Z"
      style={{
        mixBlendMode: "screen",
      }}
    />
    <path fill="#2F2A27" d="M44.522 92.692H22.25v5.62h22.272v-5.62Z" />
    <path
      fill="#2F2A27"
      d="M44.52 92.692h-2.06v5.62h2.06v-5.62Z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#2F2A27"
      d="M31.965 92.692H22.25v5.62h9.715v-5.62Z"
      style={{
        mixBlendMode: "screen",
      }}
    />
    <path
      fill="#2F2A27"
      d="M25.002 92.692H22.25v5.62h2.752v-5.62Z"
      style={{
        mixBlendMode: "screen",
      }}
    />
    <path
      fill="#BCD1E4"
      d="M13.544 54.22 7.577 17.296c-.054-.34-.72-1.087-1-1.289l-1.783-1.65c-.537-.498-.167-1.394.565-1.366l1.658.058 52.858-.008-3.76 24.974-.79 5.017-.335 2.125-2.468 15.67a68.7 68.7 0 0 1-8.575 24.051l-2.88 4.932a.985.985 0 0 1-.852.49H26.708a.977.977 0 0 1-.856-.502l-3.08-5.449a68.92 68.92 0 0 1-8.029-22.813l-.821-5.01-.378-2.308Z"
      opacity={0.24}
    />
    <path
      fill="#78BCFB"
      d="M50.966 46.283c-7.158-1.016-13.008-6.076-19.707-8.8-6.488-2.64-14.172-2.924-20.384.221l2.67 16.52.378 2.307.821 5.01a68.746 68.746 0 0 0 8.03 22.813l3.079 5.45a.99.99 0 0 0 .856.501h13.506a.99.99 0 0 0 .853-.49l2.88-4.932a68.754 68.754 0 0 0 8.575-24.05l2.262-14.356c-1.277.036-2.554-.007-3.819-.19v-.004Z"
    />
    <path
      fill="#4888C1"
      d="M18.803 56.944c1.666-.07 3.39-.343 5.134-.821 3.95-1.082 7.547-3.072 10.24-4.679.472-.28.939-.564 1.41-.852 1.46-.888 2.966-1.806 4.5-2.566 4.569-2.253 9.82-3.257 14.907-2.868l-2.468 15.67a68.702 68.702 0 0 1-8.575 24.051l-3.164 5.418H26.14l-3.363-5.951a68.92 68.92 0 0 1-8.03-22.813l-.821-5.01c.872.199 1.775.327 2.701.39"
    />
    <path
      fill="#78BCFB"
      d="M35.581 72.86c-.467.284-.938.572-1.409.852-2.693 1.608-6.29 3.597-10.24 4.679a24.12 24.12 0 0 1-3.834.728 68.364 68.364 0 0 0 2.674 5.239l3.363 5.951h14.647l3.164-5.418a68.739 68.739 0 0 0 7.213-17.488 28.984 28.984 0 0 0-11.078 2.9c-1.534.759-3.044 1.673-4.5 2.565v-.008ZM14.802 65.978c.595.101 1.202.18 1.825.218l2.176.023c1.666-.07 3.39-.342 5.134-.82 3.95-1.083 7.547-3.072 10.24-4.68.472-.28.939-.564 1.41-.852 1.46-.887 2.966-1.806 4.5-2.565 4.327-2.133 9.267-3.145 14.101-2.915l.806-5.111c-5.087-.39-10.338.615-14.908 2.869-1.533.759-3.044 1.673-4.5 2.565-.466.284-.937.572-1.408.852-2.694 1.608-6.29 3.597-10.241 4.679a23.1 23.1 0 0 1-5.134.821l-2.176-.023a17.923 17.923 0 0 1-2.701-.39l.821 5.01c.016.105.039.214.055.32Z"
    />
    <path
      fill="#BCD1E4"
      d="M13.55 54.22 7.581 17.296c-.054-.34-.72-1.086-1-1.289l-1.783-1.65c-.537-.498-.167-1.394.565-1.366l1.658.058 52.858-.008-3.76 24.974-.79 5.017-.335 2.125-2.468 15.67a68.697 68.697 0 0 1-8.575 24.051l-2.88 4.932a.986.986 0 0 1-.852.49H26.713a.977.977 0 0 1-.856-.502l-3.079-5.449a68.92 68.92 0 0 1-8.03-22.813l-.821-5.01-.378-2.308Zm47.034-30.781.83-5.263c.147-.938.56-1.288 1.047-1.288h.51c.544 0 .984-.44.984-.985l.09-3.523a1.154 1.154 0 0 0-1.152-1.183H0c0 2.721 1.966 5.13 4.55 5.594.424.077.763.393.83.817l6.173 37.709 1.074 6.566a70.91 70.91 0 0 0 8.279 23.522L24.6 91.94a.99.99 0 0 0 .856.502h15.986a.99.99 0 0 0 .852-.49l3.5-5.994a70.819 70.819 0 0 0 8.232-21.467.983.983 0 0 1 .961-.79h3.6C64.894 63.701 70 58.591 70 52.29V34.754c0-4.936-3.776-9.198-8.633-10.202-.522-.11-.876-.592-.79-1.117l.007.004Zm7.283 28.854a9.27 9.27 0 0 1-9.272 9.271h-4.017l.063-.393s0-.015.004-.023l2.643-16.78 2.654-16.866a.99.99 0 0 1 1.203-.81c3.799.873 6.718 4.212 6.718 8.066v17.535h.004Z"
    />
    <path fill="#BCD1E4" d="M3.344 12.396h57.003v4.636H5.994l-2.65-4.636Z" opacity={0.3} />
    <path
      fill="#0F0E0D"
      d="M32.453.342H30.53a2.557 2.557 0 0 0-2.557 2.557v4.033a2.557 2.557 0 0 0 2.557 2.557h1.923a2.557 2.557 0 0 0 2.557-2.557V2.899A2.557 2.557 0 0 0 32.453.342Z"
    />
    <path
      fill="#515151"
      d="M30.049 1.716h-.56V6.11h.56V1.716ZM31.771 1.716h-.56V6.11h.56V1.716ZM33.506 1.716h-.56V6.11h.56V1.716Z"
      style={{
        mixBlendMode: "screen",
      }}
    />
    <path
      fill="#232323"
      d="M35.01 4.705h-7.037v.518h7.037v-.518Z"
      style={{
        mixBlendMode: "color-dodge",
      }}
    />
    <path fill="#2F2A27" d="M59.853 7.924a2.696 2.696 0 0 0-2.697-2.697H7.252a2.696 2.696 0 0 0-2.697 2.697v.288h55.298v-.288Z" />
    <path fill="#1C1715" d="M59.853 8.212H4.555v2.99h55.298v-2.99Z" />
    <path
      fill="#2F2A27"
      d="M8.17 5.223h-.922A2.696 2.696 0 0 0 4.551 7.92v3.277h7.769V5.223H8.17Z"
      style={{
        mixBlendMode: "screen",
      }}
    />
    <path
      fill="#2F2A27"
      d="M57.145 5.223h-2.149v5.974h4.846V7.92a2.696 2.696 0 0 0-2.697-2.697Z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
  </svg>
);
export default Enseres;
